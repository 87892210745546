import React from 'react';
import { Alert, Button } from '@itandi/itandi-bb-ui';
import Styles from './StatusHandleButtons.module.scss';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { StatusReasonModal } from './StatusReasonModal';
import useItandiAccount from '~/hooks/useItandiAccount';
import { canMovingStatus } from '~/models/Staff';
import { FINISHED } from '~/models/Status';
import { SurveyModal } from '~/components/common/survey/SurveyModal';
import { Deal } from '~/models/Deal';
import { useDealSurveys } from '~/hooks/deals/useDealSurveys';
import { SurveyAnswer } from '~/models/Survey';

type Props = {
  canBackward: boolean;
  handleForwardStatus: () => Promise<void>;
  handleForwardStatusWithSurvey: (
    surveyAnswers: SurveyAnswer[]
  ) => Promise<void>;
  handleBackwardStatus: (reason: string) => Promise<void>;
  forwardDisabled: boolean;
  alertText: string | null;
  nextStatusId: number;
  deal: Deal;
  handlePartiallyFinish?: (() => Promise<void>) | null;
};

export const StatusHandleReasonButtons: React.FC<Props> = ({
  canBackward,
  handleForwardStatus,
  handleForwardStatusWithSurvey,
  handleBackwardStatus,
  forwardDisabled,
  alertText,
  nextStatusId,
  deal,
  handlePartiallyFinish = null
}: Props) => {
  const { data: account } = useItandiAccount().currentAccount();
  const {
    isOpen: openConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm
  } = useOpenHandler();
  const {
    isOpen: openBackConfirm,
    handleOpen: handleOpenBackConfirm,
    handleClose: handleCloseBackConfirm
  } = useOpenHandler();
  const {
    isOpen: openPartiallyFinishConfirm,
    handleOpen: handleOpenPartiallyFinishConfirm,
    handleClose: handleClosePartiallyFinishConfirm
  } = useOpenHandler();
  const {
    isOpen: openSurveyConfirm,
    handleOpen: handleOpenSurveyConfirm,
    handleClose: handleCloseSurveyConfirm
  } = useOpenHandler();
  const handleAcceptForward = async (): Promise<void> => {
    await handleForwardStatus();
    handleCloseConfirm();
  };
  const handleAcceptBackward = async (reason: string): Promise<void> => {
    await handleBackwardStatus(reason);
    handleCloseBackConfirm();
  };
  const handleAcceptForwardWithSurvey = async (
    surveyAnswers: SurveyAnswer[]
  ): Promise<void> => {
    await handleForwardStatusWithSurvey(surveyAnswers);
    handleCloseSurveyConfirm();
  };
  const { data: surveys } = useDealSurveys().index(deal.id);
  const surveyPresent =
    !account?.isAdmin &&
    nextStatusId === FINISHED &&
    surveys !== null &&
    surveys.length !== 0;
  const handleAcceptPartiallyFinish = async (): Promise<void> => {
    if (handlePartiallyFinish !== null) {
      await handlePartiallyFinish();
    }
  };
  if (account === null) return null;
  return (
    <div className={Styles.StatusHandleButtonRow}>
      {handlePartiallyFinish !== null && (
        <Button
          disabled={!canMovingStatus(account)}
          height="default"
          onClick={handleOpenPartiallyFinishConfirm}
          variant="tertiary"
        >
          工事を依頼せずに途中完了する
        </Button>
      )}
      <div className={Styles.RightButtons}>
        {canBackward && (
          <button
            className={Styles.BackwardButton}
            disabled={!canMovingStatus(account)}
            onClick={handleOpenBackConfirm}
            type="button"
          >
            ステータスを戻す
          </button>
        )}
        <button
          className={Styles.ForwardButton}
          disabled={forwardDisabled || !canMovingStatus(account)}
          onClick={surveyPresent ? handleOpenSurveyConfirm : handleOpenConfirm}
          type="button"
        >
          ステータスを進める
        </button>
      </div>
      <ConfirmModal
        actionText="確定"
        confirmType="save"
        handleAccept={handleAcceptPartiallyFinish}
        handleCancel={handleClosePartiallyFinishConfirm}
        isOpen={openPartiallyFinishConfirm}
      >
        <p className={Styles.ConfirmText}>
          案件を完了扱いにしてよろしいですか？
        </p>
        <Alert variant="warning">
          施工会社には工事が依頼されていない状態で完了扱いになります。
        </Alert>
      </ConfirmModal>
      <ConfirmModal
        actionText="確定"
        confirmType="save"
        handleAccept={handleAcceptForward}
        handleCancel={handleCloseConfirm}
        isOpen={openConfirm}
      >
        <p className={Styles.ConfirmText}>ステータスを進めてよろしいですか？</p>
        {alertText && <Alert variant="info">{alertText}</Alert>}
      </ConfirmModal>
      {openBackConfirm && (
        <StatusReasonModal
          actionText="ステータスを戻す"
          confirmText="ステータスを戻してよろしいですか？"
          handleAccept={handleAcceptBackward}
          handleClose={handleCloseBackConfirm}
          label="ステータスを戻す理由（施工会社に通知されます)"
          title="ステータスを戻す"
        />
      )}
      {openSurveyConfirm && surveyPresent && (
        <SurveyModal
          handleAccept={handleAcceptForwardWithSurvey}
          handleClose={handleCloseSurveyConfirm}
          surveys={surveys}
        />
      )}
    </div>
  );
};
