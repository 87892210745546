import React from 'react';
import { InputCheckBox } from '@itandi/itandi-bb-ui';
import { RiDeleteBinLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import Styles from './DealClientTodoTable.module.scss';
import { DealTodo } from '~/models/DealTodo';
import { formatDate, getTodayISO8601 } from '~/utils/DateUtil';
import { Dropdown } from '~/components/common/parts/dropdown/Dropdown';
import { DropdownList } from '~/components/common/parts/dropdown/DropdownList';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { DealClientTodoModal } from '~/components/common/deals/show/main/todos/DealClientTodoModal';
import { useDealTodos } from '~/hooks/deals/useDealTodos';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { destroy } from '~/utils/api/deals/DealTodo';
import { isSuccess } from '~/utils/api/api_handler';

type Props = DeepReadonly<{
  todo: DealTodo;
  handleCheck: (newTodo: DealTodo) => void;
}>;

export const DealClientTodoTableRow: React.FC<Props> = ({
  todo,
  handleCheck
}: Props) => {
  const id = useIdQueryHandler();
  const { mutate: mutateTodos } = useDealTodos().index(id);
  const {
    isOpen: openDeleteConfirm,
    handleOpen: handleOpenDeleteConfirm,
    handleClose: handleCloseDeleteConfirm
  } = useOpenHandler();
  const {
    isOpen: openEditModal,
    handleOpen: handleOpenEditModal,
    handleClose: handleCloseEditModal
  } = useOpenHandler();
  const handleAcceptDelete = React.useCallback(async () => {
    if (id !== null) {
      const response = await destroy(id, todo.id);
      if (isSuccess(response)) {
        mutateTodos && (await mutateTodos());
        handleCloseDeleteConfirm();
        toast.success('TODOを削除しました');
        return;
      }
      toast.error('エラーが発生しました');
    }
  }, [id, todo, mutateTodos, handleCloseDeleteConfirm]);
  const handleMutate = React.useCallback(async () => {
    mutateTodos && (await mutateTodos());
  }, [mutateTodos]);

  const onCheck = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (e.target.checked && todo.completedAt === null) {
        handleCheck({
          ...todo,
          completed: true,
          completedAt: getTodayISO8601()
        });
        return;
      }
      handleCheck({ ...todo, completed: e.target.checked });
    },
    [handleCheck, todo]
  );

  return (
    <tr className={Styles.TodoTableRow}>
      <td className={Styles.CheckColumn}>
        <InputCheckBox checked={todo.completed} onChange={onCheck} />
      </td>
      <td>
        <button
          className={Styles.TodoLink}
          onClick={handleOpenEditModal}
          type="button"
        >
          {todo.title}
        </button>
        {openEditModal && (
          <DealClientTodoModal
            handleClose={handleCloseEditModal}
            handleMutate={handleMutate}
            todo={todo}
          />
        )}
      </td>
      <td className={Styles.DueDateColumn}>
        {todo.dueDate === null ? (
          <span className={Styles.Empty}>未登録</span>
        ) : (
          <span>{formatDate(todo.dueDate)}</span>
        )}
      </td>
      <td className={Styles.CompletedAtColumn}>
        {todo.completedAt === null ? (
          <span className={Styles.Empty}>未完了</span>
        ) : (
          <span>{formatDate(todo.completedAt)}</span>
        )}
      </td>
      <td className={Styles.OperateColumn}>
        <Dropdown title="操作" variant="normal">
          <DropdownList>
            <DropdownMenu onClick={handleOpenDeleteConfirm}>
              <span className={Styles.DeleteLink}>
                <RiDeleteBinLine className={Styles.DropdownMenuIcon} />
                TODOを削除
              </span>
            </DropdownMenu>
          </DropdownList>
        </Dropdown>
        <ConfirmModal
          confirmType="delete"
          handleAccept={handleAcceptDelete}
          handleCancel={handleCloseDeleteConfirm}
          isOpen={openDeleteConfirm}
        >
          削除してよろしいですか？
        </ConfirmModal>
      </td>
    </tr>
  );
};
