import { Deal } from '~/models/Deal';
import { DealEstimationContext } from '~/models/DealEstimate';
import {
  DealInspectionReportImage,
  DealCompletionReportImage
} from '~/models/DealReportImage';

export const inEstimationDisabled = (
  deal: Deal,
  estimationContext: DealEstimationContext,
  inspectionImages: DealInspectionReportImage[]
): boolean => {
  // マケプレ案件は反社チェックIDが管理されていないとステータスを進められない
  if (
    deal.companySetting.fromMarket &&
    deal.companySetting.antisocialCheckId === null
  )
    return true;
  if (estimationContext.dealConstructions.length === 0) return true;
  if (deal.inspectionDate == null || inspectionImages.length === 0) {
    if (deal.dealType !== 'repair') return true;
    // 修繕案件で現地調査ある場合は立会日程と立会報告画像を登録しないと進められない
    if (deal.conductFieldSurvey && deal.dealType === 'repair') return true;
  }
  return false;
};

export const inConstructionDisabled = (
  inspectionImages: DealInspectionReportImage[],
  completeImages: DealCompletionReportImage[]
): boolean => inspectionImages.length === 0 || completeImages.length === 0;
