import React from 'react';
import { RiHome2Line } from 'react-icons/ri';
import { Input, InputRadio, ItandiChip } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './Block.module.scss';
import { DealParam } from '~/models/DealForm';
import useItandiAccount from '~/hooks/useItandiAccount';
import { RoomBlockPropertySelect } from '~/components/common/deals/new/block/RoomBlockPropertySelect';
import { RoomBlockPropertyInput } from '~/components/common/deals/new/block/RoomBlockPropertyInput';
import { Datepicker } from '~/components/common/parts/datepicker/Datepicker';
import { formatDate, toDate } from '~/utils/DateUtil';

export const RoomBlock: React.FC = () => {
  const { data: session } = useItandiAccount().currentAccount();
  const { register, formState, setValue, watch } = useFormContext<DealParam>();
  const { errors } = formState;
  const onChangeFloorMap = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { files } = e.target;
      if (files !== null) {
        const uploadFile: File = files[0];
        setValue('floorMap', uploadFile);
      }
    },
    [setValue]
  );
  const floorMap: File | null = watch('floorMap');
  const onClickFloorMapLink = React.useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault();
      if (floorMap !== null) {
        const url = URL.createObjectURL(floorMap);
        window.open(url);
      }
    },
    [floorMap]
  );
  const onChangeDate = React.useCallback(
    (date: Date | [Date | null, Date | null] | null, name: 'preferredDate') => {
      if (date === null || Array.isArray(date)) {
        setValue(name, null);
        return;
      }
      const dateStr = formatDate(date);
      setValue(name, dateStr);
    },
    [setValue]
  );
  const preferredDate = watch('preferredDate');
  return (
    <div className={Styles.Block}>
      <div className={Styles.BlockCaptionWrapper}>
        <div className={Styles.BlockCaption}>
          <span className={Styles.BlockIconWrapper}>
            <RiHome2Line className={Styles.BlockIcon} />
          </span>
          <span className={Styles.BlockCaptionLabel}>物件情報</span>
        </div>
      </div>
      <div className={Styles.BlockForms}>
        {session?.company?.useBkkLinkage ? (
          <RoomBlockPropertySelect />
        ) : (
          <RoomBlockPropertyInput />
        )}
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>エレベーターの有無</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputRadioGroup}>
              <InputRadio
                isInvalid={errors.enableElevator != null}
                label="なし"
                value="false"
                {...register('enableElevator')}
              />
              <InputRadio
                isInvalid={errors.enableElevator != null}
                label="あり"
                value="true"
                {...register('enableElevator')}
              />
            </div>
            {errors.enableElevator != null && (
              <div className={Styles.ErrorMessage}>
                {errors.enableElevator.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>オートロックの有無</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputRadioGroup}>
              <InputRadio
                isInvalid={errors.enableAutoLock != null}
                label="なし"
                value="false"
                {...register('enableAutoLock')}
              />
              <InputRadio
                isInvalid={errors.enableAutoLock != null}
                label="あり"
                value="true"
                {...register('enableAutoLock')}
              />
            </div>
            {errors.enableAutoLock != null && (
              <div className={Styles.ErrorMessage}>
                {errors.enableAutoLock.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>鍵の置き場所</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <Input
                isInvalid={errors.placeKeyStoring != null}
                placeholder="例）エントランス横の雨樋（番号：1234）"
                {...register('placeKeyStoring')}
              />
            </div>
            {errors.placeKeyStoring != null && (
              <div className={Styles.ErrorMessage}>
                {errors.placeKeyStoring.message}
              </div>
            )}
            <div className={Styles.Description}>
              下記について教えてください。
              <br />
              空室時：鍵を設置した場所
              <br />
              立会依頼時：鍵設置を希望する場所（希望がない場合は「希望なし」とご記載ください）
            </div>
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>無償利用が可能な駐車場</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <Input
                isInvalid={errors.freeParking != null}
                placeholder="例）エントランス前"
                {...register('freeParking')}
              />
            </div>
            {errors.freeParking != null && (
              <div className={Styles.ErrorMessage}>
                {errors.freeParking.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>間取り図</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            {floorMap != null && (
              <div>
                <a
                  className={Styles.FileLink}
                  href="#"
                  onClick={onClickFloorMapLink}
                  rel="noreferrer"
                  target="_blank"
                >
                  {floorMap.name}
                </a>
              </div>
            )}
            <div className={Styles.InputWrapper}>
              <input
                accept="image/*,application/pdf"
                onChange={onChangeFloorMap}
                type="file"
              />
            </div>
            {errors.floorMap != null && (
              <div className={Styles.ErrorMessage}>
                {errors.floorMap.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>入居予定日</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div>
              <Datepicker
                onChange={(date): void => {
                  onChangeDate(date, 'preferredDate');
                }}
                selected={toDate(preferredDate)}
              />
            </div>
            <div className={Styles.Description}>
              次回の入居予定がある場合は予定日を入力してください。
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
