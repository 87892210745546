import React, { ReactNode } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import {
  RiDeleteBinLine,
  RiDownload2Line,
  RiMoreFill,
  RiEyeOffLine,
  RiEyeLine,
  RiChat4Line,
  RiChatOffLine,
  RiCameraOffLine,
  RiCameraLine,
  RiIndeterminateCircleLine,
  RiHammerLine
} from 'react-icons/ri';
import clsx from 'clsx';
import { useOpenHandler } from '@itandi/itandi-bb-ui';
import Styles from './ReportImagesAreaSpRow.module.scss';
import { DealReportImage } from '~/models/DealReportImage';
import { ImagePreviewModal } from '~/components/common/parts/modal/ImagePreviewModal';
import { HideInputForm } from '~/components/common/parts/input/HideInputForm';
import { NskResponse } from '~/models/Api';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { Dropdown } from '~/components/common/parts/dropdown/Dropdown';
import { DropdownList } from '~/components/common/parts/dropdown/DropdownList';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';

type Props = {
  reportImage: DealReportImage;
  handleDownloadImage: (reportImage: DealReportImage) => void;
  index: number;
  handleMutateReportImages: () => Promise<void>;
  handleUpdateSeq:
    | ((newOrderImages: DealReportImage[]) => Promise<void>)
    | null;
  handleUpdate: (submitValue: DealReportImage) => Promise<NskResponse<void>>;
  handleDestroy:
    | ((reportImage: DealReportImage) => Promise<NskResponse<void>>)
    | null;
  companyType: 'management' | 'own_management' | 'vendor';
  displaySeqNo: number | null;
};

const renderView = (content: string | null | undefined): ReactNode => (
  <div className={Styles.ContentView}>
    {content == null || content === '' ? (
      <span className={Styles.YetInput}>未入力</span>
    ) : (
      <span>{content}</span>
    )}
  </div>
);

export const ReportImagesAreaSpRow: React.FC<Props> = ({
  reportImage,
  index,
  handleDownloadImage,
  handleMutateReportImages,
  handleUpdateSeq,
  handleUpdate,
  handleDestroy,
  companyType,
  displaySeqNo
}: Props) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const {
    isOpen: isOpenDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete
  } = useOpenHandler();
  const isVendor = companyType === 'vendor';
  const isManagement = companyType === 'management';
  const [viewReportImage, setViewReportImage] =
    React.useState<DealReportImage>(reportImage);
  const isShared = viewReportImage?.shared;
  const isOutputPdfImage = viewReportImage?.outputPdfImage;
  const isOutputPdfNote = viewReportImage?.outputPdfNote;
  const submitPoint = async (submitValue: string): Promise<void> => {
    const newReportImage = { ...viewReportImage, point: submitValue };
    setViewReportImage(newReportImage);
    await handleUpdate(newReportImage);
  };
  const submitNote = async (submitValue: string): Promise<void> => {
    const newReportImage = { ...viewReportImage, note: submitValue };
    setViewReportImage(newReportImage);
    await handleUpdate(newReportImage);
  };
  const onClickImage = (e: React.MouseEvent): void => {
    e.preventDefault();
    handleOpen();
  };
  const handleAcceptDestroy = async (): Promise<void> => {
    handleDestroy !== null && (await handleDestroy(reportImage));
    handleMutateReportImages !== null && (await handleMutateReportImages());
  };
  const handleAcceptUpdateShared = async (
    submitValue: boolean
  ): Promise<void> => {
    const newReportImage = { ...reportImage, shared: submitValue };
    await handleUpdate(newReportImage);
    setViewReportImage(newReportImage);
  };
  const handleAcceptUpdateOutputPdf = async (
    submitKey: 'outputPdfImage' | 'outputPdfNote',
    submitValue: boolean
  ): Promise<void> => {
    const newReportImage = { ...reportImage, [submitKey]: submitValue };
    await handleUpdate(newReportImage);
    setViewReportImage(newReportImage);
    await handleMutateReportImages();
  };
  const handleAcceptUpdateConstruction = async (
    submitValue: 'unspecified' | 'targeted'
  ): Promise<void> => {
    await handleUpdate({ ...reportImage, construction: submitValue });
    handleMutateReportImages && (await handleMutateReportImages());
  };
  return (
    <Draggable
      key={reportImage.id}
      draggableId={String(reportImage.id)}
      index={index}
      isDragDisabled={handleUpdateSeq === null}
    >
      {(provided: DraggableProvided): React.ReactElement<HTMLElement> => (
        <div
          className={clsx(
            reportImage.construction === 'targeted' && Styles.Targeted
          )}
        >
          <div
            ref={provided.innerRef}
            className={Styles.ReportImageRow}
            style={provided.draggableProps.style}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.draggableProps}
          >
            <div
              className={Styles.DragHandleWrapper}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.dragHandleProps}
            >
              <img alt="drag handle" src="/assets/DragHandle.svg" />
            </div>
            <div className={Styles.ImageBlock}>
              <a href="" onClick={onClickImage}>
                <img
                  alt="写真"
                  className={Styles.Image}
                  src={
                    viewReportImage.thumbnailImageUrl === null
                      ? viewReportImage.imageUrl
                      : viewReportImage.thumbnailImageUrl
                  }
                />
              </a>
              {(isVendor || (!isVendor && isOutputPdfImage)) && (
                <div className={Styles.NumberPlate}>No.{displaySeqNo}</div>
              )}
              {isVendor && !isShared && (
                <p className={Styles.NotSharedPlate}>
                  管理会社に共有されません
                </p>
              )}
              {!isVendor && !isOutputPdfImage && (
                <p className={Styles.NotOutPutPdf}>
                  写真は報告書に出力されません
                </p>
              )}
              <div className={Styles.ButtonWrapper}>
                <Dropdown
                  className={Styles.MenuDropdown}
                  noBorder
                  title={
                    <span className={Styles.MenuButton}>
                      <RiMoreFill className={Styles.MenuIcon} />
                    </span>
                  }
                  useCaret={false}
                  variant="link"
                >
                  <DropdownList>
                    <DropdownMenu
                      onClick={(): void => handleDownloadImage(reportImage)}
                    >
                      <RiDownload2Line className={Styles.DropdownMenuIcon} />
                      画像をダウンロード
                    </DropdownMenu>
                    {!isManagement && (
                      <DropdownMenu
                        onClick={(): void => {
                          handleAcceptUpdateConstruction(
                            viewReportImage?.construction === 'targeted'
                              ? 'unspecified'
                              : 'targeted'
                          ).then();
                        }}
                      >
                        {viewReportImage?.construction === 'targeted' ? (
                          <>
                            <RiIndeterminateCircleLine
                              className={Styles.DropdownMenuIcon}
                            />
                            施工推奨から外す
                          </>
                        ) : (
                          <>
                            <RiHammerLine className={Styles.DropdownMenuIcon} />
                            施工推奨にする
                          </>
                        )}
                      </DropdownMenu>
                    )}
                    {isVendor && (
                      <DropdownMenu
                        onClick={(): void => {
                          handleAcceptUpdateShared(!viewReportImage.shared);
                        }}
                      >
                        {isShared ? (
                          <>
                            <RiEyeOffLine className={Styles.DropdownMenuIcon} />
                            写真を共有しない
                          </>
                        ) : (
                          <>
                            <RiEyeLine className={Styles.DropdownMenuIcon} />
                            写真を共有する
                          </>
                        )}
                      </DropdownMenu>
                    )}
                    {!isVendor && (
                      <DropdownMenu
                        onClick={(): void => {
                          handleAcceptUpdateOutputPdf(
                            'outputPdfImage',
                            !viewReportImage?.outputPdfImage
                          ).then();
                        }}
                      >
                        {isOutputPdfImage ? (
                          <>
                            <RiCameraOffLine
                              className={Styles.DropdownMenuIcon}
                            />
                            写真を報告書に出力しない
                          </>
                        ) : (
                          <>
                            <RiCameraLine className={Styles.DropdownMenuIcon} />
                            写真を報告書に出力する
                          </>
                        )}
                      </DropdownMenu>
                    )}
                    {!isVendor && (
                      <DropdownMenu
                        className={!isOutputPdfImage ? Styles.disabled : ''}
                        onClick={(): void => {
                          handleAcceptUpdateOutputPdf(
                            'outputPdfNote',
                            !viewReportImage?.outputPdfNote
                          ).then();
                        }}
                      >
                        {isOutputPdfNote ? (
                          <>
                            <RiChatOffLine
                              className={Styles.DropdownMenuIcon}
                            />
                            備考を報告書に出力しない
                          </>
                        ) : (
                          <>
                            <RiChat4Line className={Styles.DropdownMenuIcon} />
                            備考を報告書に出力する
                          </>
                        )}
                      </DropdownMenu>
                    )}
                    {!isManagement && (
                      <DropdownMenu onClick={handleOpenDelete}>
                        <span className={Styles.DeleteLink}>
                          <RiDeleteBinLine
                            className={Styles.DropdownMenuIcon}
                          />
                          画像を削除
                        </span>
                      </DropdownMenu>
                    )}
                  </DropdownList>
                </Dropdown>
              </div>
              <ConfirmModal
                confirmType="delete"
                handleAccept={handleAcceptDestroy}
                handleCancel={handleCloseDelete}
                isOpen={isOpenDelete}
              >
                この画像を削除してよろしいですか？
              </ConfirmModal>
              <ImagePreviewModal
                handleClose={handleClose}
                isOpen={isOpen}
                reportImage={viewReportImage}
              />
            </div>
            <div className={Styles.RemarksBlock}>
              {/* <div className={Styles.No}>No.{index + 1}</div> */}
              {reportImage.construction === 'targeted' && (
                <div className={Styles.RemarkRow}>
                  <div className={Styles.ConstructionState}>施工推奨箇所</div>
                </div>
              )}
              <div className={Styles.RemarkRow}>
                <div className={Styles.Label}>撮影場所</div>
                <div className={Styles.Content}>
                  {!isManagement ? (
                    <>
                      <HideInputForm
                        handleSubmit={submitPoint}
                        value={viewReportImage.point ?? ''}
                        viewComponent={renderView(viewReportImage.point)}
                      />
                    </>
                  ) : (
                    <>{renderView(viewReportImage.point)}</>
                  )}
                </div>
              </div>
              <div className={Styles.RemarkRow}>
                <div className={Styles.Label}>備考</div>
                <div className={Styles.Content}>
                  {!isManagement ? (
                    <>
                      <HideInputForm
                        as="textarea"
                        handleSubmit={submitNote}
                        value={viewReportImage.note ?? ''}
                        viewComponent={renderView(viewReportImage.note)}
                      />
                    </>
                  ) : (
                    <>{renderView(viewReportImage.note)}</>
                  )}
                  {!isVendor && isOutputPdfImage && !isOutputPdfNote && (
                    <p className={Styles.NotOutPutPdfNote}>
                      備考は報告書に出力されません
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
