export const summaryFileTemplateTypes = [
  'BillingRenteeSummaryFileTemplate',
  'BillingOwnerSummaryFileTemplate'
] as const;
export type SummaryFileTemplateType = (typeof summaryFileTemplateTypes)[number];
export type SummaryFileTemplate = {
  id: number;
  type: SummaryFileTemplateType;
  fileName: string;
};

export const summaryFileTemplateLabels = {
  BillingRenteeSummaryFileTemplate: '入居者宛の請求書',
  BillingOwnerSummaryFileTemplate: 'オーナー宛の請求書'
} as const satisfies {
  [key in SummaryFileTemplateType]: string;
};
