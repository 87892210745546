import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Grid,
  Box,
  Flex,
  FormControl,
  Input,
  ComboBox,
  ComboBoxOptionType
} from '@itandi/itandi-bb-ui';
import { DealSearchCondition } from '~/models/Deal';
import { CompanyVendor, convertToComboBoxOption } from '~/models/CompanyVendor';
import { useSubtypes } from '~/hooks/useSubtypes';
import { DealTypeCheckBox } from './detail/DealTypeCheckBox';
import { SearchConditionDate } from './detail/SearchConditionDate';
import { CompanyStoreCombobox } from './detail/CompanyStoreCombobox';
import { StaffCombobox } from './detail/StaffCombobox';
import useItandiAccount from '~/hooks/useItandiAccount';
import { StatusCombobox } from './detail/StatusCombobox';
import { useStatuses } from '~/hooks/useStatuses';

type Props = {
  companyVendors: CompanyVendor[];
  selectedCompanyVendor: CompanyVendor | null;
  dealSearchCondition: DealSearchCondition;
};

export const DealSearchDetailTab: React.FC<Props> = ({
  companyVendors,
  selectedCompanyVendor,
  dealSearchCondition
}: Props) => {
  const { data: subtypes } = useSubtypes().useIndex();
  const { register, setValue } = useFormContext<DealSearchCondition>();
  const { data: session } = useItandiAccount().currentAccount();
  const { data: statuses } = useStatuses().index();
  const onChangeCompanyVendor = React.useCallback(
    async (option: ComboBoxOptionType | null): Promise<void> => {
      setValue('companyVendorId', option ? Number(option.value) : undefined);
    },
    [setValue]
  );
  if (subtypes === null || session === null || statuses === null) {
    return null;
  }
  return (
    <Box margin={16}>
      <Grid gap={8}>
        <FormControl label="ステータス" variant="Vertical">
          <StatusCombobox
            dealSearchCondition={dealSearchCondition}
            statuses={statuses}
          />
        </FormControl>
        <FormControl label="案件タイプ" variant="Vertical">
          <Flex marginY={8} wrap="wrap">
            <DealTypeCheckBox
              key="restoration"
              dealSearchCondition={dealSearchCondition}
              dealType="restoration"
              labelText="原状回復"
            />
            <DealTypeCheckBox
              key="repair"
              dealSearchCondition={dealSearchCondition}
              dealType="repair"
              labelText="修繕"
            />
            {subtypes.map((subtype) => (
              <DealTypeCheckBox
                key={subtype.id}
                dealSearchCondition={dealSearchCondition}
                dealSubtypeId={subtype.id}
                dealType="other"
                labelText={subtype.name}
              />
            ))}
          </Flex>
        </FormControl>
        <FormControl label="案件名" variant="Vertical">
          <Input {...register('name')} placeholder="案件名" />
        </FormControl>
        <FormControl label="所在地" variant="Vertical">
          <Input {...register('address')} placeholder="所在地" />
        </FormControl>
        <FormControl label="入居者" variant="Vertical">
          <Input {...register('tenantName')} placeholder="入居者" />
        </FormControl>
        {session.store?.masterAccount && (
          <FormControl label="店舗" variant="Vertical">
            <CompanyStoreCombobox dealSearchCondition={dealSearchCondition} />
          </FormControl>
        )}
        <FormControl label="担当者" variant="Vertical">
          <StaffCombobox dealSearchCondition={dealSearchCondition} />
        </FormControl>
        <FormControl label="施工会社" variant="Vertical">
          <ComboBox
            defaultValue={
              selectedCompanyVendor
                ? convertToComboBoxOption(selectedCompanyVendor)
                : null
            }
            isMulti={false}
            onChange={onChangeCompanyVendor}
            options={companyVendors.map((c: CompanyVendor) =>
              convertToComboBoxOption(c)
            )}
            placeholder="施工会社"
          />
        </FormControl>
        <FormControl label="立会日" variant="Vertical">
          <Flex alignItems="center">
            <SearchConditionDate
              attributeName="inspectionMinDate"
              defaultValue={dealSearchCondition.inspectionMinDate}
            />
            〜
            <SearchConditionDate
              attributeName="inspectionMaxDate"
              defaultValue={dealSearchCondition.inspectionMaxDate}
            />
          </Flex>
        </FormControl>
        <FormControl label="工期開始日" variant="Vertical">
          <Flex alignItems="center">
            <SearchConditionDate
              attributeName="constructionMinStartDate"
              defaultValue={dealSearchCondition.constructionMinStartDate}
            />
            〜
            <SearchConditionDate
              attributeName="constructionMaxStartDate"
              defaultValue={dealSearchCondition.constructionMaxStartDate}
            />
          </Flex>
        </FormControl>
        <FormControl label="工期終了日" variant="Vertical">
          <Flex alignItems="center">
            <SearchConditionDate
              attributeName="constructionMinEndDate"
              defaultValue={dealSearchCondition.constructionMinEndDate}
            />
            〜
            <SearchConditionDate
              attributeName="constructionMaxEndDate"
              defaultValue={dealSearchCondition.constructionMaxEndDate}
            />
          </Flex>
        </FormControl>
        <FormControl label="登録日" variant="Vertical">
          <Flex alignItems="center">
            <SearchConditionDate
              attributeName="registrationMinDate"
              defaultValue={dealSearchCondition.registrationMinDate}
            />
            〜
            <SearchConditionDate
              attributeName="registrationMaxDate"
              defaultValue={dealSearchCondition.registrationMaxDate}
            />
          </Flex>
        </FormControl>
      </Grid>
    </Box>
  );
};
