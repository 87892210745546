import React from 'react';
import { toast } from 'react-toastify';
import { Alert } from '@itandi/itandi-bb-ui';
import { ProgressBar } from 'react-bootstrap';
import { useDeals } from '~/hooks/useDeals';
import { useDealInspectionReportImages } from '~/hooks/deals/useDealInspectionReportImages';
import Loading from '~/components/common/parts/loading/Loading';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import Styles from './Page.module.scss';
import { ReportImagesArea } from '~/components/common/reports/ReportImagesArea';
import { ReportImagesAreaSp } from '~/components/common/reports/ReportImagesAreaSp';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/deals';
import { PATH } from '~/pages/deals/[id]';
import { getDownloadPath as getDownloadPdfPath } from '~/utils/api/deals/DealInspectionReport';
import {
  create as postInspectionReportImages,
  update,
  getDownloadPath as getDownloadImagePath,
  destroy,
  updateSeq,
  getDownloadZipPath
} from '~/utils/api/deals/DealInspectionReportImage';
import {
  DealReportImage,
  NUMBER_OF_UPLOAD_AT_ONCE
} from '~/models/DealReportImage';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { NskResponse } from '~/models/Api';
import { isOwnStatus } from '~/models/Status';
import { TopArea } from '~/components/common/reports/TopArea';
import { isSuccess } from '~/utils/api/api_handler';

export const Page: React.FC = () => {
  const id = useIdQueryHandler();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const { data: deal } = useDeals().show(id);
  const { data: reportImages, mutate: mutateReportImages } =
    useDealInspectionReportImages().index(id);
  if (deal === null || reportImages === null) {
    return <Loading isLoading />;
  }
  const ownStatus = isOwnStatus(deal.status);
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: PATH(deal.id), caption: '案件詳細', active: false },
    { path: '', caption: '立会報告画像', active: true }
  ];
  const handleDrop = async (files: File[]): Promise<void> => {
    setProgress(0);
    setUploading(true);
    const arrFiles: File[][] = [];
    const totalFiles: number = files.length;
    let finishedFiles = 0;
    while (files.length > 0) {
      arrFiles.push(files.splice(0, NUMBER_OF_UPLOAD_AT_ONCE));
    }
    for await (const sendFiles of arrFiles) {
      const result = await postInspectionReportImages(sendFiles, deal.id);
      if (isSuccess(result)) {
        mutateReportImages && (await mutateReportImages());
      } else {
        toast.error('エラーが発生しました');
        return;
      }
      finishedFiles += sendFiles.length;
      setProgress(Math.round((finishedFiles / totalFiles) * 100));
      mutateReportImages && (await mutateReportImages());
    }
    setUploading(false);
    toast.success('画像を登録しました');
  };
  const handleDownloadPdf = (): void => {
    const path = getDownloadPdfPath(deal.id);
    window.open(path);
  };
  const handleDownloadZip = (): void => {
    const path = getDownloadZipPath(deal.id);
    window.open(path);
  };
  const handleDownloadImage = (reportImage: DealReportImage): void => {
    const path = getDownloadImagePath(reportImage);
    window.open(path);
  };
  const handleUpdate = async (
    submitValue: DealReportImage
  ): Promise<NskResponse<void>> => update(submitValue);
  const handleMutateReportImages = async (): Promise<void> => {
    mutateReportImages && (await mutateReportImages());
  };
  const handleDestroy = async (
    reportImage: DealReportImage
  ): Promise<NskResponse<void>> => destroy(reportImage);
  const handleUpdateSeq = async (
    newOrderItems: DealReportImage[]
  ): Promise<void> => {
    updateSeq(newOrderItems, Number(id));
  };
  return (
    <DefaultV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor={false}
        noBackground
      >
        <div className={Styles.MainWrapper}>
          <h2 className={Styles.ReportTitle}>{`立会報告画像${
            ownStatus ? 'の登録' : ''
          }`}</h2>
          {ownStatus && (
            <>
              {uploading ? (
                <div className={Styles.ProgressBar}>
                  <p className={Styles.message}>
                    <Alert variant="warning">
                      現在写真をアップロード中です。
                      <br />
                      画面を移動すると、アップロードが正常に完了しない場合があります。
                    </Alert>
                  </p>
                  <ProgressBar label={`${progress}%`} now={progress} />
                </div>
              ) : (
                <TopArea handleDrop={handleDrop} />
              )}
            </>
          )}
          <ReportImagesArea
            companyType={ownStatus ? 'own_management' : 'management'}
            handleDestroy={handleDestroy}
            handleDownloadImage={handleDownloadImage}
            handleDownloadPdf={handleDownloadPdf}
            handleDownloadZip={handleDownloadZip}
            handleMutateReportImages={handleMutateReportImages}
            handleUpdate={handleUpdate}
            handleUpdateSeq={ownStatus ? handleUpdateSeq : null}
            reportImages={reportImages}
          />
          <ReportImagesAreaSp
            companyType={ownStatus ? 'own_management' : 'management'}
            handleDestroy={handleDestroy}
            handleDownloadImage={handleDownloadImage}
            handleDownloadPdf={handleDownloadPdf}
            handleDownloadZip={handleDownloadZip}
            handleMutateReportImages={handleMutateReportImages}
            handleUpdate={handleUpdate}
            handleUpdateSeq={ownStatus ? handleUpdateSeq : null}
            reportImages={reportImages}
          />
        </div>
      </DealLayout>
    </DefaultV2>
  );
};
