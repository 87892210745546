import React from 'react';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import Link from 'next/link';
import Styles from './CompanyVendorList.module.scss';
import { CompanyVendor } from '~/models/CompanyVendor';
import { VENDOR_COMPANY_ITANDI_ID } from '~/models/VendorCompany';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { InviteCompanyVendorModal } from './InviteCompanyVendorModal';
import { destroy } from '~/utils/api/CompanyVendor';
import { isSuccess } from '~/utils/api/api_handler';
import { useCompanyVendors } from '~/hooks/useCompanyVendors';
import { CompanyVendorModal } from '~/components/specifics/settings/company_vendors/CompanyVendorModal';
import { PATH as CONSTRUCTION_ITEM_SETTING_PATH } from '~/pages/settings/company_vendors/[vendorCompanyId]/construction_item_settings';
import { ItandiAccount } from '~/models/ItandiAccount';

type Props = {
  companyVendor: CompanyVendor;
  currentAccount: ItandiAccount;
  index: number;
};

export const CompanyVendorListRow: React.FC<Props> = ({
  companyVendor,
  currentAccount,
  index
}: Props) => {
  const { mutate: mutateCompanyVendors } = useCompanyVendors().index();
  const {
    isOpen: showUpdate,
    handleOpen: openUpdate,
    handleClose: closeUpdate
  } = useOpenHandler();
  const {
    isOpen: confirmDestroy,
    handleOpen: openConfirmDestroy,
    handleClose: closeConfirmDestroy
  } = useOpenHandler();
  const {
    isOpen: showInvite,
    handleOpen: openInvite,
    handleClose: closeInvite
  } = useOpenHandler();
  const onClickEdit = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      openUpdate();
    },
    [openUpdate]
  );
  const onClickDelete = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      openConfirmDestroy();
    },
    [openConfirmDestroy]
  );
  const onClickInvite = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      openInvite();
    },
    [openInvite]
  );
  const handleDeleteCompanyVendor = React.useCallback(async () => {
    const result = await destroy(companyVendor);
    if (isSuccess(result)) {
      mutateCompanyVendors && (await mutateCompanyVendors());
      toast.success('削除しました');
      return;
    }
    toast.error(`エラーが発生しました。${result.message}`);
  }, [companyVendor, mutateCompanyVendors]);
  const canInviteCompanyVendor =
    !currentAccount.company?.fromMarket || currentAccount.isAdmin;
  return (
    <Draggable draggableId={String(companyVendor.id)} index={index}>
      {(provided: DraggableProvided): React.ReactElement<HTMLElement> => (
        <tr
          ref={provided.innerRef}
          className={clsx(Styles.ListRow)}
          style={provided.draggableProps.style}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.draggableProps}
        >
          <td
            className={Styles.OrderHandle}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.dragHandleProps}
          >
            <img alt="drag handle" src="/assets/DragHandle.svg" />
          </td>
          <td className={Styles.ListCol}>{companyVendor.name}</td>
          <td className={clsx(Styles.ListCol, Styles.LinkageTagCol)}>
            {canInviteCompanyVendor &&
              (companyVendor.vendorCompany !== null ? (
                <span className={Styles.LinkageTag}>アカウント連携済</span>
              ) : (
                <>
                  {companyVendor.companyVendorPayload !== null ? (
                    <span className={Styles.LinkageTag}>招待済</span>
                  ) : (
                    <a
                      className={Styles.InviteLinkageTag}
                      href="#"
                      onClick={onClickInvite}
                    >
                      アカウント連携
                    </a>
                  )}
                </>
              ))}
          </td>
          <td className={clsx(Styles.ListCol, Styles.UnitAmount)}>
            {companyVendor.vendorCompany !== null && (
              <Link
                className={Styles.UnitAmountLink}
                href={CONSTRUCTION_ITEM_SETTING_PATH(
                  companyVendor.vendorCompany.id
                )}
              >
                単価一覧
              </Link>
            )}
          </td>
          <td className={clsx(Styles.ListCol, Styles.Edit)}>
            <a className={Styles.EditLink} href="#" onClick={onClickEdit}>
              編集
            </a>
            {companyVendor.vendorCompany?.id !== VENDOR_COMPANY_ITANDI_ID && (
              <a className={Styles.DeleteLink} href="#" onClick={onClickDelete}>
                削除
              </a>
            )}
            {showInvite && (
              <InviteCompanyVendorModal
                companyVendor={companyVendor}
                handleClose={closeInvite}
              />
            )}
            {showUpdate && (
              <CompanyVendorModal
                companyVendor={companyVendor}
                handleClose={closeUpdate}
              />
            )}
            {confirmDestroy && (
              <ConfirmModal
                confirmType="delete"
                handleAccept={handleDeleteCompanyVendor}
                handleCancel={closeConfirmDestroy}
                isOpen={confirmDestroy}
              >
                施工会社を削除してよろしいですか？
              </ConfirmModal>
            )}
          </td>
        </tr>
      )}
    </Draggable>
  );
};
