import React, { ReactNode } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortEnd,
  SortEndHandler
} from 'react-sortable-hoc';
import { RiDownload2Line, RiFolderZipLine } from 'react-icons/ri';
import { Button, Flex } from '@itandi/itandi-bb-ui';
import Styles from './ReportImagesArea.module.scss';
import { DealReportImage } from '~/models/DealReportImage';
import { ReportImagesAreaBlock } from '~/components/common/reports/ReportImagesAreaBlock';
import { NskResponse } from '../../../models/Api';

type Props = {
  reportImages: DealReportImage[];
  handleDownloadImage: (reportImage: DealReportImage) => void;
  handleMutateReportImages: (() => Promise<void>) | null;
  handleUpdateSeq?:
    | ((newOrderImages: DealReportImage[]) => Promise<void>)
    | null;
  handleUpdate: (submitValue: DealReportImage) => Promise<NskResponse<void>>;
  handleDestroy?:
    | ((reportImage: DealReportImage) => Promise<NskResponse<void>>)
    | null;
  handleDownloadPdf: () => void;
  handleDownloadZip?: () => void;
  companyType: 'management' | 'own_management' | 'vendor';
};

type SortableContainerProps = {
  items: DealReportImage[];
};

export const ReportImagesArea: React.FC<Props> = ({
  reportImages,
  handleDownloadImage,
  handleMutateReportImages,
  handleUpdateSeq = null,
  handleUpdate,
  handleDestroy = null,
  handleDownloadPdf,
  handleDownloadZip,
  companyType
}: Props) => {
  // 並び替えのためのstate
  const [orderImages, setOrderImages] = React.useState<DealReportImage[]>([]);
  const isVendor = companyType === 'vendor';
  React.useEffect(() => {
    setOrderImages(reportImages);
  }, [reportImages]);
  let tmpSeqNo = 0;
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }: SortEnd) => {
    tmpSeqNo = 0;
    if (oldIndex === newIndex) {
      return;
    }
    const moveImage = orderImages[oldIndex];
    const newOrderItems: DealReportImage[] = [];
    const wkOrderImages = [...orderImages];
    wkOrderImages.splice(oldIndex, 1);
    wkOrderImages.splice(newIndex, 0, moveImage);
    wkOrderImages.forEach((r: DealReportImage, i: number) => {
      newOrderItems.push({ ...r, seq: i + 1 });
    });
    setOrderImages(newOrderItems);
    handleUpdateSeq && handleUpdateSeq(newOrderItems).then();
  };
  const renderImageBlock = (
    reportImage: DealReportImage,
    index: number
  ): ReactNode => {
    const displaySeqNo = ((): number | null => {
      if (isVendor) {
        return index + 1;
      }
      if (reportImage.outputPdfImage) {
        tmpSeqNo += 1;
        return tmpSeqNo;
      }
      return null;
    })();
    const SortableItem = SortableElement(() => (
      <ReportImagesAreaBlock
        key={reportImage.id}
        companyType={companyType}
        displaySeqNo={displaySeqNo}
        handleDestroy={handleDestroy}
        handleDownloadImage={handleDownloadImage}
        handleMutateReportImages={handleMutateReportImages}
        handleUpdate={handleUpdate}
        handleUpdateSeq={handleUpdateSeq}
        reportImage={reportImage}
      />
    ));
    return <SortableItem key={reportImage.id} index={index} />;
  };
  const SortableList = SortableContainer<SortableContainerProps>(
    (props: SortableContainerProps) => {
      const { items } = props;
      return (
        <div className={Styles.ReportImageList}>
          {items.map(renderImageBlock)}
        </div>
      );
    }
  );
  return (
    <div className={Styles.ReportImagesArea}>
      <Flex gap={16} margin="8px 2px">
        <Button
          disabled={orderImages.length <= 0}
          endIcon={<RiDownload2Line />}
          onClick={handleDownloadPdf}
          variant="secondary"
        >
          報告書ダウンロード
        </Button>
        {handleDownloadZip && (
          <Button
            disabled={orderImages.length <= 0}
            endIcon={<RiFolderZipLine />}
            onClick={handleDownloadZip}
            variant="secondary"
          >
            画像一括ダウンロード
          </Button>
        )}
      </Flex>
      <SortableList
        axis="xy"
        items={orderImages}
        onSortEnd={onSortEnd}
        onSortStart={(): void => {
          tmpSeqNo = 0;
        }}
        useDragHandle
      />
    </div>
  );
};
