import React from 'react';
import {
  Input,
  InputText,
  InputSelect,
  ItandiChip
} from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './TodoTemplateModalForm.module.scss';
import { TodoTemplateEdit } from '~/models/TodoTemplate';
import { useStatuses } from '~/hooks/useStatuses';

export const TodoTemplateModalForm: React.FC = () => {
  const { data: statuses } = useStatuses().index();
  const {
    formState: { errors },
    register
  } = useFormContext<TodoTemplateEdit>();
  return (
    <div className={Styles.Forms}>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          作成ステータス
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <InputSelect
              isInvalid={errors.statusId != null}
              {...register('statusId', {
                valueAsNumber: true
              })}
            >
              {statuses !== null &&
                statuses.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.useType === 'own' && '[自社管理]'}
                    {status.name}
                  </option>
                ))}
            </InputSelect>
            {errors.statusId != null && (
              <div className={Styles.InvalidFeedback}>
                {errors.statusId.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          TODOタイトル
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <Input
            isInvalid={errors.title != null}
            placeholder="例）書類確認"
            {...register('title')}
          />
          {errors.title != null && (
            <div className={Styles.InvalidFeedback}>{errors.title.message}</div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          メモ
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <InputText
            isInvalid={errors.memo != null}
            placeholder="例）書類確認手順"
            {...register('memo')}
          />
          {errors.memo != null && (
            <div className={Styles.InvalidFeedback}>{errors.memo.message}</div>
          )}
        </div>
      </div>
    </div>
  );
};
