import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { toast } from 'react-toastify';
import { RiArrowDownSLine } from 'react-icons/ri';
import Styles from './RelatedDealListTop.module.scss';
import { usePropertyDeals } from '~/hooks/usePropertyDeals';
import { DealWithPrices } from '~/models/Deal';
import { OWN_IN_ESTIMATION, SEND_ESTIMATE } from '~/models/Status';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { update } from '~/utils/api/PropertyDealStatus';
import { isSuccess } from '~/utils/api/api_handler';
import useItandiAccount from '~/hooks/useItandiAccount';
import { canMovingStatus } from '~/models/Staff';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { getDownloadPath as getEstimationDownloadPath } from '~/utils/api/property_deals/DealEstimationFile';
import { getDownloadPath as getBillingDownloadPath } from '~/utils/api/property_deals/DealBillingFile';
import { SimpleDropdown } from '~/components/common/parts/dropdown/SimpleDropdown';
import { DropdownList } from '~/components/common/parts/dropdown/DropdownList';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';
import { BillingType } from '~/models/DealBilling';

// すべての案件が見積送信済みか自社管理の見積作成中の場合のみ一括でステータスを進めるボタンを押せるようにする
const allowedStatuses: number[] = [SEND_ESTIMATE, OWN_IN_ESTIMATION];

/**
 * @for client
 * @constructor
 */
export const RelatedDealListTop: React.FC = () => {
  const { data: account } = useItandiAccount().currentAccount();
  const statusMoveable = React.useMemo(() => {
    if (account === null) return false;
    return canMovingStatus(account);
  }, [account]);
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const id = useIdQueryHandler();
  const { data: propertyDeal, mutate: mutatePropertyDeal } =
    usePropertyDeals().show(id);
  const disableBulkStatusUpdate = React.useMemo(() => {
    if (!statusMoveable) return true;
    if (propertyDeal === null) return true;
    return propertyDeal.deals.some(
      (deal: DealWithPrices) => !allowedStatuses.includes(deal.status.id)
    );
  }, [propertyDeal, statusMoveable]);

  const handleForwardStatus = React.useCallback(async () => {
    if (propertyDeal === null) {
      return;
    }
    const response = await update(propertyDeal.id);
    if (isSuccess(response)) {
      mutatePropertyDeal && (await mutatePropertyDeal());
      handleClose();
      toast.success('ステータスを一括で進めました');
      return;
    }
    toast.error(`エラーが発生しました。${response.message}`);
  }, [handleClose, mutatePropertyDeal, propertyDeal]);

  const onClickEstimationSummaries = React.useCallback(() => {
    if (propertyDeal === null) {
      return;
    }
    const path = getEstimationDownloadPath(propertyDeal.id);
    window.open(path);
  }, [propertyDeal]);

  const onClickBillingSummaries = React.useCallback(
    (billingType: BillingType) => {
      if (propertyDeal === null) {
        return;
      }
      const path = getBillingDownloadPath(propertyDeal.id, billingType);
      window.open(path);
    },
    [propertyDeal]
  );

  if (propertyDeal === null) {
    return null;
  }
  return (
    <div className={Styles.TopContainer}>
      <h2 className={Styles.Title}>
        関連する案件一覧
        <span className={Styles.Count}>{`${propertyDeal.deals.length}件`}</span>
      </h2>
      <div className={Styles.ButtonArea}>
        <SimpleDropdown
          className={Styles.ButtonDropdown}
          placement="bottom-start"
          title={
            <span className={Styles.ButtonDropdownTitle}>
              見積・請求のサマリーを出力
              <RiArrowDownSLine className={Styles.Caret} />
            </span>
          }
        >
          <DropdownList>
            <DropdownMenu onClick={onClickEstimationSummaries}>
              施工会社からの見積
            </DropdownMenu>
            <DropdownMenu
              onClick={(): void => onClickBillingSummaries('rentee')}
            >
              入居者への請求
            </DropdownMenu>
            <DropdownMenu
              onClick={(): void => onClickBillingSummaries('owner')}
            >
              オーナーへの請求
            </DropdownMenu>
          </DropdownList>
        </SimpleDropdown>
        <Button
          className={Styles.Button}
          disabled={disableBulkStatusUpdate}
          onClick={handleOpen}
          variant="secondary"
        >
          一括でステータスを進める
        </Button>
        <ConfirmModal
          confirmType="save"
          handleAccept={handleForwardStatus}
          handleCancel={handleClose}
          isOpen={isOpen}
        >
          {propertyDeal.deals.length}
          件の案件のステータスを進めます。よろしいですか？
        </ConfirmModal>
      </div>
    </div>
  );
};
