import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import Styles from './DealVendorChangeModal.module.scss';
import { Modal } from '~/components/common/parts/modal/Modal';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import { DealVendorCompany } from '~/models/DealVendorCompany';
import { update } from '~/utils/api/vendors/deals/DealVendorCompany';
import { useDeals } from '~/hooks/vendors/useDeals';
import { isSuccess } from '~/utils/api/api_handler';
import { useVendorUsers } from '~/hooks/vendors/useVendorUsers';
import { VendorUser } from '~/models/VendorUser';

type Props = {
  handleClose: () => void;
  dealVendorCompany: DealVendorCompany;
};

/**
 * Vendorが自分のDealVendorCompanyの情報をupdateするためのModal
 *
 * @param handleClose
 * @param dealVendorCompany
 * @constructor
 * @for vendor
 */
export const DealVendorChangeModal: React.FC<Props> = ({
  handleClose,
  dealVendorCompany
}: Props) => {
  const { id } = useRouter().query;
  const { data: deal, mutate: mutateDeal } = useDeals().show(Number(id));
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const { data: vendorUsers } = useVendorUsers().index();
  const [userId, setUserId] = React.useState<number | null>(
    dealVendorCompany.user?.id ?? null
  );
  const onChangeUser = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === '') {
      setUserId(null);
      return;
    }
    setUserId(Number(e.target.value));
  };
  const onSubmit = async (): Promise<void> => {
    if (deal === null || userId === null) {
      return;
    }
    setSubmitting(true);
    const result = await update(deal.id, dealVendorCompany, userId);
    if (isSuccess(result)) {
      mutateDeal && (await mutateDeal());
      handleClose();
      toast.success('更新しました');
      return;
    }
    setSubmitting(false);
    toast.error(`エラーが発生しました。${result.message}`);
  };
  if (vendorUsers === null) {
    return null;
  }
  const disabled = userId === null;
  return (
    <Modal handleClose={handleClose} isOpen title="情報変更">
      <FormGroup>
        <FormLabel>担当者</FormLabel>
        <Form.Control as="select" onChange={onChangeUser} value={userId ?? ''}>
          <option key={-1} value="">
            選択してください
          </option>
          {vendorUsers.map(
            (vendorUser: VendorUser): ReactNode => (
              <option key={vendorUser.id} value={vendorUser.id}>
                {vendorUser.name}
              </option>
            )
          )}
        </Form.Control>
      </FormGroup>
      <div className={Styles.Row}>
        <div className={Styles.Col}>
          <button
            className={Styles.CancelButton}
            // disabled={isSubmitting}
            onClick={handleClose}
            type="button"
          >
            キャンセル
          </button>
        </div>
        <div className={Styles.Col}>
          <button
            className={Styles.SubmitButton}
            disabled={submitting || disabled}
            onClick={onSubmit}
            type="button"
          >
            確定
          </button>
        </div>
      </div>
    </Modal>
  );
};
