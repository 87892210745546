import { NskResponse } from '~/models/Api';
import {
  deleteRequest,
  postRequest,
  putRequest
} from '~/utils/api/api_handler';
import { DealReportImage } from '~/models/DealReportImage';

const paths = {
  create: '/vendors/deals/:deal_id/deal_completion_report_images',
  update: '/vendors/deals/:deal_id/deal_completion_report_images/:id',
  destroy: '/vendors/deals/:deal_id/deal_completion_report_images/:id',
  updateSeq: '/vendors/deals/:deal_id/deal_completion_report_images/update_seq',
  show: `${process.env.NEXT_PUBLIC_API_HOST}/api/vendors/deals/:deal_id/deal_completion_report_images/:id`
};

export const create = async (
  files: File[],
  dealId: number
): Promise<NskResponse<void>> => {
  const formData = new FormData();
  files.forEach((file: File): void => {
    formData.append('images[]', file);
  });
  return postRequest(
    paths.create.replace(':deal_id', String(dealId)),
    formData
  );
};

export const update = async (
  reportImage: DealReportImage
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update
      .replace(':deal_id', String(reportImage.dealId))
      .replace(':id', String(reportImage.id)),
    { deal_completion_report_image: reportImage }
  );

export const updateSeq = async (
  reportImages: DealReportImage[],
  dealId: number
): Promise<NskResponse<void>> =>
  postRequest(paths.updateSeq.replace(':deal_id', String(dealId)), {
    deal_completion_report_images: reportImages
  });

export const destroy = async (
  reportImage: DealReportImage
): Promise<NskResponse<void>> =>
  deleteRequest(
    paths.destroy
      .replace(':deal_id', String(reportImage.dealId))
      .replace(':id', String(reportImage.id))
  );

export const getDownloadPath = (reportImage: DealReportImage): string =>
  paths.show
    .replace(':deal_id', String(reportImage.dealId))
    .replace(':id', String(reportImage.id));
