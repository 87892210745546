import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';
import {
  CompanySetting,
  CompanySettingWithItemSetting
} from '~/models/CompanySetting';

const paths = {
  index: '/vendors/company_settings',
  show: '/vendors/company_settings/:id'
};

type TIndex = (
  searchWord: string,
  pageNumber: number | null
) => SwrReturnType<Pageable<CompanySettingWithItemSetting>>;
type TShow = (id: number | null) => SwrReturnType<CompanySetting>;
type ReturnType = {
  index: TIndex;
  show: TShow;
};
export const useCompanySettings = (): ReturnType => {
  const index: TIndex = (searchWord: string, pageNumber: number | null) => {
    const { data, mutate, error, isLoading } = useSWR({
      url: paths.index,
      params: { searchWord, pageNumber }
    });
    return {
      data: data ?? null,
      mutate,
      isLoading,
      error
    };
  };
  const show: TShow = (id: number | null) => {
    const { data, mutate, error, isLoading } = useSWR<CompanySetting>(
      id === null ? null : paths.show.replace(':id', String(id))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading,
      error
    };
  };
  return { index, show };
};
