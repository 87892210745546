import React from 'react';
import { ComboBox, ComboBoxOptionType } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { DealSearchCondition } from '~/models/Deal';
import { useStaffs } from '~/hooks/useStaffs';
import { Staff } from '~/models/Staff';

type Props = {
  dealSearchCondition: DealSearchCondition;
};

export const StaffCombobox: React.FC<Props> = ({
  dealSearchCondition
}: Props) => {
  const { data: staffs } = useStaffs().index();
  const { setValue } = useFormContext<DealSearchCondition>();
  const selectedStaff =
    staffs?.find(({ id }) => id === dealSearchCondition.staffId) || null;
  const [selectedStaffOption] = React.useState<ComboBoxOptionType | null>(
    selectedStaff
      ? {
          label: selectedStaff.name,
          value: String(selectedStaff.id)
        }
      : null
  );
  const onChangeStaff = React.useCallback(
    async (option: ComboBoxOptionType | null): Promise<void> => {
      setValue('staffId', option ? Number(option.value) : undefined);
    },
    [setValue]
  );
  if (staffs === null) {
    return null;
  }
  return (
    <ComboBox
      defaultValue={selectedStaffOption || null}
      isMulti={false}
      onChange={onChangeStaff}
      options={staffs.map((c: Staff) => ({
        label: c.name,
        value: String(c.id)
      }))}
      placeholder="担当者"
    />
  );
};
