import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ItandiChip, useOpenHandler, Button } from '@itandi/itandi-bb-ui';
import { RiLinksLine } from 'react-icons/ri';
import Styles from './Block.module.scss';
import { DealUpdateParam } from '~/models/DealForm';
import { buildPropertyByDeal, Property } from '~/models/kensaku_kun/property';
import { PropertySelectModal } from '~/components/common/kensaku_kun/PropertySelectModal/PropertySelectModal';
import { Deal } from '~/models/Deal';

type Props = {
  deal: Deal;
};

export const AmaterasPropertyBlock: React.FC<Props> = ({ deal }: Props) => {
  const property = buildPropertyByDeal(deal);
  if (property === null) return null;
  const { setValue } = useFormContext<DealUpdateParam>();
  const [currentProperty, setCurrentProperty] =
    React.useState<Property<'room' | 'building'>>(property);
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const handleSelect = (
    selectedProperty: Property<'room' | 'building'>
  ): void => {
    setCurrentProperty(selectedProperty);
    setValue('amaterasPropertyId', selectedProperty.propertyId);
    handleClose();
  };
  return (
    <div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>物件情報</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div className={Styles.SelectedPropertyLabel}>
            <div>
              {currentProperty.type === 'building' && (
                <span className={Styles.PropertyTypeLabel}>棟</span>
              )}
              {currentProperty.type === 'room' && (
                <span className={Styles.PropertyTypeLabel}>部屋</span>
              )}
              <span>{currentProperty.name}</span>
              {currentProperty.type === 'room' && (
                <span>{currentProperty.roomNumber}</span>
              )}
            </div>
            <div className={Styles.SelectedPropertyAddress}>
              <span>{currentProperty.address}</span>
            </div>
          </div>
          <div className={Styles.EditButton}>
            <Button
              height="small"
              onClick={handleOpen}
              startIcon={<RiLinksLine className={Styles.Icon} />}
              variant="secondary"
            >
              変更
            </Button>
            {isOpen && (
              <PropertySelectModal
                handleClose={handleClose}
                handleSelect={handleSelect}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
