import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import Styles from './ImportConversionTop.module.scss';
import { ImportType } from '~/models/ImportLog';
import { AddModal } from '~/components/specifics/settings/imports/[importType]/import_conversions/AddModal';
import { useOpenHandler } from '~/hooks/useOpenHandler';

type Props = {
  importType: ImportType;
};

export const ImportConversionTop: React.FC<Props> = ({ importType }: Props) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  return (
    <div className={Styles.ImportConversionTop}>
      <Button onClick={handleOpen} variant="secondary">
        取込変換設定を追加
      </Button>
      {isOpen && <AddModal handleClose={handleClose} importType={importType} />}
    </div>
  );
};
