import React from 'react';
import { useFormContext } from 'react-hook-form';
import Styles from './DealEditorBody.module.scss';
import { DealParam } from '~/models/DealForm';
import { DealTypeBlock } from './block/DealTypeBlock';
import { MoveOutBlock } from './block/MoveOutBlock';
import { OtherBlock } from './block/OtherBlock';
import { RepairBlock } from './block/RepairBlock';
import { RoomBlock } from './block/RoomBlock';
import { TenantBlock } from './block/TenantBlock';

export const DealEditorBody: React.FC = () => {
  const { watch } = useFormContext<DealParam>();
  const DealType = watch('dealType');
  return (
    <div className={Styles.DealEditorBody}>
      <DealTypeBlock />
      <RoomBlock />
      {DealType === 'repair' && <RepairBlock />}
      <TenantBlock />
      <MoveOutBlock />
      <OtherBlock />
    </div>
  );
};
