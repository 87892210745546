import React from 'react';
import Styles from './StatusHandleButtons.module.scss';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { useOpenHandler } from '~/hooks/useOpenHandler';

type Props = {
  canBackward: boolean;
  handleForwardStatus: () => Promise<void>;
  handleBackwardStatus: () => Promise<void>;
  forwardDisabled: boolean;
  backwardDisabled: boolean;
};

export const StatusHandleButtons: React.FC<Props> = ({
  canBackward,
  handleForwardStatus,
  handleBackwardStatus,
  forwardDisabled,
  backwardDisabled
}: Props) => {
  const {
    isOpen: openConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm
  } = useOpenHandler();
  const {
    isOpen: openBackConfirm,
    handleOpen: handleOpenBackConfirm,
    handleClose: handleCloseBackConfirm
  } = useOpenHandler();
  const handleAcceptForward = async (): Promise<void> => {
    await handleForwardStatus();
    handleCloseConfirm();
  };
  const handleAcceptBackward = async (): Promise<void> => {
    await handleBackwardStatus();
    handleCloseBackConfirm();
  };
  return (
    <div className={Styles.StatusHandleButtonRow}>
      <div className={Styles.RightButtons}>
        {canBackward && (
          <button
            className={Styles.BackwardButton}
            disabled={backwardDisabled}
            onClick={handleOpenBackConfirm}
            type="button"
          >
            ステータスを戻す
          </button>
        )}
        <button
          className={Styles.ForwardButton}
          disabled={forwardDisabled}
          onClick={handleOpenConfirm}
          type="button"
        >
          ステータスを進める
        </button>
      </div>
      <ConfirmModal
        actionText="確定"
        confirmType="save"
        handleAccept={handleAcceptForward}
        handleCancel={handleCloseConfirm}
        isOpen={openConfirm}
      >
        ステータスを進めてよろしいですか？
      </ConfirmModal>
      <ConfirmModal
        actionText="確定"
        confirmType="delete"
        handleAccept={handleAcceptBackward}
        handleCancel={handleCloseBackConfirm}
        isOpen={openBackConfirm}
      >
        ステータスを戻してよろしいですか？
      </ConfirmModal>
    </div>
  );
};
