import React, { ReactNode } from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { DealType } from '~/models/Deal';
import { Subtype } from '~/models/Subtype';
import { Table } from '~/components/common/parts/table/Table';
import { TableHeader } from '~/components/common/parts/table/TableHeader';
import { TableBody } from '~/components/common/parts/table/TableBody';
import { useTodoTemplates } from '~/hooks/useTodoTemplates';
import {
  filterTodoTemplatesByDealType,
  TodoTemplate
} from '~/models/TodoTemplate';
import { TodoTemplateListRow } from '~/components/common/todo_templates/TodoTemplateListRow';
import Styles from './TodoTemplateList.module.scss';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { TodoTemplateModal } from '~/components/common/todo_templates/TodoTemplateModal';

type Props = DeepReadonly<{
  dealType: DealType;
  subtype?: Subtype | null;
}>;

export const TodoTemplateList: React.FC<Props> = ({
  dealType,
  subtype = null
}: Props) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const { data: todoTemplates, mutate: mutateTodoTemplates } =
    useTodoTemplates().useIndex();
  const handleMutate = React.useCallback(async () => {
    mutateTodoTemplates && (await mutateTodoTemplates());
  }, [mutateTodoTemplates]);
  const filteredTodoTemplates = React.useMemo(() => {
    const templates = filterTodoTemplatesByDealType(
      dealType,
      subtype?.id ?? null,
      todoTemplates
    );

    // ステータス順に並び替える
    return templates.sort((a, b) => a.statusId - b.statusId);
  }, [dealType, subtype, todoTemplates]);

  const renderRow = React.useCallback(
    (todoTemplate: TodoTemplate): ReactNode => (
      <TodoTemplateListRow
        key={todoTemplate.id}
        dealType={dealType}
        subtype={subtype}
        todoTemplate={todoTemplate}
      />
    ),
    [dealType, subtype]
  );
  return (
    <div>
      <Table>
        <TableHeader>
          <th>作成ステータス</th>
          <th>タイトル</th>
          <th>操作</th>
        </TableHeader>
        <TableBody>{filteredTodoTemplates.map(renderRow)}</TableBody>
      </Table>
      <div className={Styles.ButtonArea}>
        <Button height="default" onClick={handleOpen} variant="secondary">
          TODOテンプレートの追加
        </Button>
      </div>
      {isOpen && (
        <TodoTemplateModal
          dealType={dealType}
          handleClose={handleClose}
          handleMutate={handleMutate}
          subtypeId={subtype?.id ?? null}
        />
      )}
    </div>
  );
};
