import React from 'react';
import { useRouter } from 'next/router';
import cx from 'clsx';
import Styles from './DealSearchDetail.module.scss';
import { DealSearchPartnerVendor } from '~/components/common/deals/search/DealSearchPartnerVendor';
import { DealSearchCompanyId } from '~/components/common/deals/search/DealSearchCompanyId';
import { DealSearchStatusId } from '~/components/common/deals/search/DealSearchStatusId';
import { DealSearchVendorUserId } from '~/components/common/deals/search/DealSearchVendorUserId';
import { PATH } from '~/pages/vendors/deals';
import { DealSearchCondition } from '~/models/Deal';
import { convertQueryStringUrl } from '~/utils/DealSearchConditionUtils';
import { useStatuses } from '~/hooks/vendors/useStatuses';

type Props = {
  isOpen: boolean;
  condition: DealSearchCondition;
};

export const DealSearchDetail: React.FC<Props> = ({
  isOpen,
  condition
}: Props) => {
  const { data: statuses } = useStatuses().index();
  const router = useRouter();
  const handleChangeCondition = React.useCallback(
    async (newCondition: DealSearchCondition): Promise<void> => {
      const newUrl = convertQueryStringUrl(PATH, newCondition);
      await router.replace(newUrl);
    },
    [router]
  );
  return (
    <div
      className={cx(Styles.DealSearchDetailContainer, !isOpen && Styles.Close)}
    >
      <h3 className={Styles.SearchTitle}>詳細検索</h3>
      <div className={Styles.SearchDropdownList}>
        <DealSearchPartnerVendor
          condition={condition}
          handleChangeCondition={handleChangeCondition}
        />
        <DealSearchCompanyId
          condition={condition}
          handleChangeCondition={handleChangeCondition}
        />
        {statuses !== null && (
          <DealSearchStatusId
            condition={condition}
            handleChangeCondition={handleChangeCondition}
            isVendor
            statuses={statuses}
          />
        )}
        <DealSearchVendorUserId
          condition={condition}
          handleChangeCondition={handleChangeCondition}
        />
      </div>
    </div>
  );
};
