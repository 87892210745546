import React from 'react';
import { ItandiChip, Input } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './Block.module.scss';
import { DealParam } from '~/models/DealForm';

export const RoomBlockPropertyInput: React.FC = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<DealParam>();
  return (
    <>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabel}>
          <span className={Styles.FormLabelText}>物件名</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormContent}>
          <div className={Styles.InputWrapper}>
            <Input
              isInvalid={errors.roomName != null}
              placeholder="例）サンプルマンション"
              {...register('roomName')}
            />
          </div>
          {errors.roomName != null && (
            <div className={Styles.ErrorMessage}>{errors.roomName.message}</div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabel}>
          <span className={Styles.FormLabelText}>部屋番号</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormContent}>
          <div className={Styles.RoomNumberInputWrapper}>
            <Input
              isInvalid={errors.roomNumber != null}
              placeholder="例）101"
              {...register('roomNumber')}
            />
          </div>
          {errors.roomNumber != null && (
            <div className={Styles.ErrorMessage}>
              {errors.roomNumber.message}
            </div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabel}>
          <span className={Styles.FormLabelText}>物件住所</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormContent}>
          <div className={Styles.InputWrapper}>
            <Input
              isInvalid={errors.address != null}
              placeholder="例）東京都港区六本木1-2-3"
              {...register('address')}
            />
          </div>
          {errors.address != null && (
            <div className={Styles.ErrorMessage}>{errors.address.message}</div>
          )}
        </div>
      </div>
    </>
  );
};
