import React from 'react';
import { toast } from 'react-toastify';
import { RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import { Button } from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { TableRow } from '~/components/common/parts/table/TableRow';
import { Subtype } from '~/models/Subtype';
import { Dropdown } from '~/components/common/parts/dropdown/Dropdown';
import { DropdownList } from '~/components/common/parts/dropdown/DropdownList';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { destroy } from '~/utils/api/Subtype';
import { isSuccess } from '~/utils/api/api_handler';
import { useSubtypes } from '~/hooks/useSubtypes';
import { SubtypeModal } from '~/components/specifics/settings/subtypes/SubtypeModal';
import Styles from './SubtypeListRow.module.scss';
import { PATH } from '~/pages/settings/subtypes/[subtypeId]/todo_templates';

type Props = DeepReadonly<{
  subtype: Subtype;
}>;

export const SubtypeListRow: React.FC<Props> = ({ subtype }: Props) => {
  const router = useRouter();
  const { mutate: mutateSubtypes } = useSubtypes().useIndex();
  const {
    isOpen: openEdit,
    handleOpen: handleOpenEdit,
    handleClose: handleCloseEdit
  } = useOpenHandler();
  const {
    isOpen: openDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete
  } = useOpenHandler();
  const handleMutate = React.useCallback(async () => {
    mutateSubtypes && (await mutateSubtypes());
  }, [mutateSubtypes]);
  const handleDeleteAccept = React.useCallback(async () => {
    const response = await destroy(subtype.id);
    if (isSuccess(response)) {
      mutateSubtypes && (await mutateSubtypes());
      handleCloseDelete();
      toast.success('削除しました');
    }
  }, [mutateSubtypes, handleCloseDelete, subtype]);
  return (
    <TableRow key={subtype.id}>
      <td>{subtype.name}</td>
      <td>{subtype.foreignKey}</td>
      <td>
        <Button
          height="small"
          onClick={(): void => {
            router.push(PATH(subtype.id)).then();
          }}
        >
          設定
        </Button>
      </td>
      <td>
        <Dropdown title="操作" variant="normal">
          <DropdownList>
            <DropdownMenu onClick={handleOpenEdit}>
              <span>
                <RiPencilLine className={Styles.DropdownMenuIcon} />
                編集
              </span>
            </DropdownMenu>
            <DropdownMenu onClick={handleOpenDelete}>
              <span className={Styles.DeleteLink}>
                <RiDeleteBinLine className={Styles.DropdownMenuIcon} />
                削除
              </span>
            </DropdownMenu>
          </DropdownList>
        </Dropdown>
        {openEdit && (
          <SubtypeModal
            handleClose={handleCloseEdit}
            handleMutate={handleMutate}
            subtype={subtype}
          />
        )}
        <ConfirmModal
          confirmType="delete"
          handleAccept={handleDeleteAccept}
          handleCancel={handleCloseDelete}
          isOpen={openDelete}
          title="削除"
        >
          削除してよろしいですか？
        </ConfirmModal>
      </td>
    </TableRow>
  );
};
