import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import {
  RiDeleteBinLine,
  RiDownload2Line,
  RiMoreFill,
  RiEyeLine,
  RiEyeOffLine,
  RiChat4Line,
  RiChatOffLine,
  RiCameraOffLine,
  RiCameraLine,
  RiHammerLine,
  RiIndeterminateCircleLine
} from 'react-icons/ri';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useOpenHandler } from '@itandi/itandi-bb-ui';
import Styles from './ReportImagesAreaBlock.module.scss';
import { DealReportImage } from '~/models/DealReportImage';
import { ImagePreviewModal } from '~/components/common/parts/modal/ImagePreviewModal';
import { ReportImagesAreaBlockRemarks } from '~/components/common/reports/ReportImagesAreaBlockRemarks';
import { NskResponse } from '../../../models/Api';
import { Dropdown } from '~/components/common/parts/dropdown/Dropdown';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';
import { DropdownList } from '../parts/dropdown/DropdownList';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { isSuccess } from '~/utils/api/api_handler';

type Props = {
  reportImage: DealReportImage;
  handleDownloadImage: (reportImage: DealReportImage) => void;
  handleMutateReportImages: (() => Promise<void>) | null;
  handleUpdateSeq:
    | ((newOrderImages: DealReportImage[]) => Promise<void>)
    | null;
  handleUpdate: (submitValue: DealReportImage) => Promise<NskResponse<void>>;
  handleDestroy:
    | ((reportImage: DealReportImage) => Promise<NskResponse<void>>)
    | null;
  companyType: 'management' | 'own_management' | 'vendor';
  displaySeqNo: number | null;
};

const DragHandle = SortableHandle(() => (
  <div className={Styles.DragHandleWrapper}>
    <img alt="drag handle" src="/assets/DragHandle.svg" />
  </div>
));

export const ReportImagesAreaBlock: React.FC<Props> = ({
  reportImage,
  handleDownloadImage,
  handleMutateReportImages,
  handleUpdateSeq,
  handleUpdate,
  handleDestroy,
  companyType,
  displaySeqNo
}: Props) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const {
    isOpen: isOpenDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete
  } = useOpenHandler();
  const onClickImage = (e: React.MouseEvent): void => {
    e.preventDefault();
    handleOpen();
  };
  const isVendor = companyType === 'vendor';
  const isManagement = companyType === 'management';
  const [viewReportImage, setViewReportImage] =
    React.useState<DealReportImage>(reportImage);
  const isShared = viewReportImage?.shared;
  const isOutputPdfImage = viewReportImage?.outputPdfImage;
  const isOutputPdfNote = viewReportImage?.outputPdfNote;
  const handleAcceptDestroy = async (): Promise<void> => {
    if (handleDestroy === null) {
      return;
    }
    const result = await handleDestroy(reportImage);
    if (isSuccess(result)) {
      handleMutateReportImages && (await handleMutateReportImages());
      toast.success('削除しました');
    }
  };
  const handleAcceptUpdateShared = async (
    submitValue: boolean
  ): Promise<void> => {
    const newReportImage = { ...reportImage, shared: submitValue };
    await handleUpdate(newReportImage);
    setViewReportImage(newReportImage);
  };
  const handleAcceptUpdateOutputPdf = async (
    submitKey: 'outputPdfImage' | 'outputPdfNote',
    submitValue: boolean
  ): Promise<void> => {
    const newReportImage = { ...reportImage, [submitKey]: submitValue };
    await handleUpdate(newReportImage);
    handleMutateReportImages && (await handleMutateReportImages());
  };
  const handleAcceptUpdateConstruction = async (
    submitValue: 'unspecified' | 'targeted'
  ): Promise<void> => {
    await handleUpdate({ ...reportImage, construction: submitValue });
    handleMutateReportImages && (await handleMutateReportImages());
  };
  return (
    <div
      className={clsx(
        Styles.ImageBlockWrapper,
        reportImage.construction === 'targeted' && Styles.Targeted
      )}
    >
      <div className={Styles.ImageBlock}>
        {handleUpdateSeq !== null && <DragHandle />}
        <div className={Styles.ImageWrapper}>
          <a href="" onClick={onClickImage}>
            <img
              alt="写真"
              className={Styles.Image}
              src={
                reportImage.thumbnailImageUrl === null
                  ? reportImage.imageUrl
                  : reportImage.thumbnailImageUrl
              }
            />
          </a>
          {(isVendor || (!isVendor && isOutputPdfImage)) && (
            <div className={Styles.NumberPlate}>No.{displaySeqNo}</div>
          )}
          {isVendor && !isShared && (
            <p className={Styles.NotSharedPlate}>管理会社に共有されません</p>
          )}
          {!isVendor && !isOutputPdfImage && (
            <p className={Styles.NotOutPutPdf}>写真は報告書に出力されません</p>
          )}
          <div className={Styles.ButtonWrapper}>
            <Dropdown
              className={Styles.MenuDropdown}
              noBorder
              placement="bottom-start"
              title={
                <span className={Styles.MenuButton}>
                  <RiMoreFill className={Styles.MenuIcon} />
                </span>
              }
              useCaret={false}
              variant="link"
            >
              <DropdownList>
                <DropdownMenu
                  onClick={(): void => handleDownloadImage(reportImage)}
                >
                  <RiDownload2Line className={Styles.DropdownMenuIcon} />
                  画像をダウンロード
                </DropdownMenu>
                {!isManagement && (
                  <DropdownMenu
                    onClick={(): void => {
                      handleAcceptUpdateConstruction(
                        viewReportImage?.construction === 'targeted'
                          ? 'unspecified'
                          : 'targeted'
                      ).then();
                    }}
                  >
                    {viewReportImage?.construction === 'targeted' ? (
                      <>
                        <RiIndeterminateCircleLine
                          className={Styles.DropdownMenuIcon}
                        />
                        {reportImage.type === 'DealInspectionReportImage' ? (
                          <>施工推奨から外す</>
                        ) : (
                          <>施工対象から外す</>
                        )}
                      </>
                    ) : (
                      <>
                        <RiHammerLine className={Styles.DropdownMenuIcon} />
                        {reportImage.type === 'DealInspectionReportImage' ? (
                          <>施工推奨にする</>
                        ) : (
                          <>施工対象にする</>
                        )}
                      </>
                    )}
                  </DropdownMenu>
                )}
                {isVendor && (
                  <DropdownMenu
                    onClick={(): void => {
                      handleAcceptUpdateShared(!viewReportImage?.shared);
                    }}
                  >
                    {isShared ? (
                      <>
                        <RiEyeOffLine className={Styles.DropdownMenuIcon} />
                        写真を共有しない
                      </>
                    ) : (
                      <>
                        <RiEyeLine className={Styles.DropdownMenuIcon} />
                        写真を共有する
                      </>
                    )}
                  </DropdownMenu>
                )}
                {!isVendor && (
                  <DropdownMenu
                    onClick={(): void => {
                      handleAcceptUpdateOutputPdf(
                        'outputPdfImage',
                        !viewReportImage?.outputPdfImage
                      ).then();
                    }}
                  >
                    {isOutputPdfImage ? (
                      <>
                        <RiCameraOffLine className={Styles.DropdownMenuIcon} />
                        写真を報告書に出力しない
                      </>
                    ) : (
                      <>
                        <RiCameraLine className={Styles.DropdownMenuIcon} />
                        写真を報告書に出力する
                      </>
                    )}
                  </DropdownMenu>
                )}
                {!isVendor && (
                  <DropdownMenu
                    className={!isOutputPdfImage ? Styles.disabled : ''}
                    onClick={(): void => {
                      handleAcceptUpdateOutputPdf(
                        'outputPdfNote',
                        !viewReportImage?.outputPdfNote
                      ).then();
                    }}
                  >
                    {isOutputPdfNote ? (
                      <>
                        <RiChatOffLine className={Styles.DropdownMenuIcon} />
                        備考を報告書に出力しない
                      </>
                    ) : (
                      <>
                        <RiChat4Line className={Styles.DropdownMenuIcon} />
                        備考を報告書に出力する
                      </>
                    )}
                  </DropdownMenu>
                )}
                {!isManagement && (
                  <DropdownMenu onClick={handleOpenDelete}>
                    <span className={Styles.DeleteLink}>
                      <RiDeleteBinLine className={Styles.DropdownMenuIcon} />
                      画像を削除
                    </span>
                  </DropdownMenu>
                )}
              </DropdownList>
            </Dropdown>
          </div>
          <ConfirmModal
            confirmType="delete"
            handleAccept={handleAcceptDestroy}
            handleCancel={handleCloseDelete}
            isOpen={isOpenDelete}
          >
            この画像を削除してよろしいですか？
          </ConfirmModal>
          <ImagePreviewModal
            handleClose={handleClose}
            isOpen={isOpen}
            reportImage={reportImage}
          />
        </div>
        <div>
          <ReportImagesAreaBlockRemarks
            companyType={companyType}
            handleDestroy={handleDestroy}
            handleUpdate={handleUpdate}
            reportImage={reportImage}
          />
        </div>
      </div>
    </div>
  );
};
