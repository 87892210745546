import React from 'react';
import SettingStyles from '../SettingStyle.module.scss';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { ClientSettingLayout } from '~/components/common/layouts/settings/ClientSettingLayout';
import { CompanySettingForm } from '~/components/specifics/settings/company_settings/CompanySettingForm';
import useItandiAccount from '~/hooks/useItandiAccount';

export const Page: React.FC = () => {
  const { data: session } = useItandiAccount().currentAccount();
  return (
    <DefaultV2>
      <ClientSettingLayout>
        <h2 className={SettingStyles.SettingTitle}>会社情報設定</h2>
        {session !== null && session.company !== null && (
          <CompanySettingForm companySetting={session.company} />
        )}
      </ClientSettingLayout>
    </DefaultV2>
  );
};
