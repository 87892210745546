import React from 'react';
import { ModalBody, ModalHeader, Modalv2 } from '@itandi/itandi-bb-ui';
import { BuildingSelectPage } from '~/components/common/kensaku_kun/PropertySelectModal/BuildingSelectPage';
import { RoomSelectPage } from '~/components/common/kensaku_kun/PropertySelectModal/RoomSelectPage';
import { Property } from '~/models/kensaku_kun/property';

type Props = DeepReadonly<{
  handleClose: () => void;
  handleSelect: (property: Property<'room' | 'building'>) => void;
}>;

type Page = 'building' | 'room';

export const PropertySelectModal: React.FC<Props> = ({
  handleClose,
  handleSelect
}: Props) => {
  const [buildingProperty, setBuildingProperty] =
    React.useState<Property<'building'> | null>(null);
  const [page, setPage] = React.useState<Page>('building');
  const handleBackToBuilding = React.useCallback(() => {
    setPage('building');
    setBuildingProperty(null);
  }, []);
  const handleSelectBuilding = React.useCallback(
    (selectBuilding: Property<'building'>) => {
      setBuildingProperty(selectBuilding);
      setPage('room');
    },
    []
  );
  return (
    <Modalv2 handleClose={handleClose} isOpen>
      <ModalHeader title="物件選択" />
      <ModalBody>
        {page === 'building' && (
          <BuildingSelectPage handleSelectBuilding={handleSelectBuilding} />
        )}
        {page === 'room' && buildingProperty && (
          <RoomSelectPage
            handleBackToBuilding={handleBackToBuilding}
            handleSelect={handleSelect}
            selectBuilding={buildingProperty}
          />
        )}
      </ModalBody>
    </Modalv2>
  );
};
