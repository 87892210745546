import { CompanyStoreSettingEdit } from '~/models/CompanyStoreSetting';
import { NskResponse } from '~/models/Api';
import { putRequest } from '~/utils/api/api_handler';

const paths = {
  update: '/admin/company_store_setting'
} as const;

export const update = async (
  companyStoreSetting: CompanyStoreSettingEdit
): Promise<NskResponse> => putRequest(paths.update, { companyStoreSetting });
