import React from 'react';
import Link from 'next/link';
import { RiMapPinLine } from 'react-icons/ri';
import Styles from './DealRow.module.scss';
import { DealIndexVendor } from '~/models/Deal';
import { PATH as VENDOR_DEAL_PATH } from '~/pages/vendors/deals/[id]';
import { DealRowContent } from './DealRowContent';
import { useStatuses } from '~/hooks/vendors/useStatuses';
import { StatusBar } from '~/components/common/parts/status/StatusBar';
import { getWithVendorStatuses } from '~/models/Status';

type Props = {
  deal: DealIndexVendor;
};

export const DealRow: React.FC<Props> = ({ deal }: Props) => {
  const { data: statuses } = useStatuses().index();
  if (statuses === null) {
    return <></>;
  }
  const displayStatuses = getWithVendorStatuses(statuses);
  return (
    <div className={Styles.DealRow}>
      <div className={Styles.RowTopArea}>
        <div className={Styles.RowTop}>
          <div className={Styles.DealLinkWrapper}>
            <Link className={Styles.DealLink} href={VENDOR_DEAL_PATH(deal.id)}>
              {deal.name} {deal.roomNumber}
            </Link>
            <div className={Styles.Address}>
              <RiMapPinLine className={Styles.AddressIcon} />
              {deal.address}
            </div>
          </div>
          <StatusBar currentId={deal.status} statuses={displayStatuses} />
        </div>
      </div>
      <DealRowContent deal={deal} />
    </div>
  );
};
