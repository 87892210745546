import React, { ReactNode } from 'react';
import { RiCalendarLine } from 'react-icons/ri';
import Styles from './ScheduleInputNavigation.module.scss';
import { Button } from '~/components/common/parts/button/Button';
import { formatDate } from '~/utils/DateUtil';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ScheduleInputModal } from './ScheduleInputModal';
import { NskResponse } from '~/models/Api';
import {
  CONSTRUCTION_SCHEDULE_LABELS,
  ConstructionScheduleType,
  DealConstructionScheduleEdit
} from '~/models/DealConstructionSchedule';
import {
  TYPE_CLEANING,
  TYPE_MISC_CONSTRUCTION,
  TYPE_RECOVERING_CONSTRUCTION
} from '~/constants/DealConstructionConst';
import { Deal } from '~/models/Deal';

const constructionNumbers: ConstructionScheduleType[] = [
  TYPE_MISC_CONSTRUCTION,
  TYPE_RECOVERING_CONSTRUCTION,
  TYPE_CLEANING
];

type Props = {
  deal: Deal;
  handleSubmit: (
    schedules: DealConstructionScheduleEdit[]
  ) => Promise<NskResponse<void>>;
  handleMutate: () => Promise<void>;
};

export const ScheduleInputNavigation: React.FC<Props> = ({
  deal,
  handleSubmit,
  handleMutate
}: Props) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  return (
    <div className={Styles.NavigationBlock}>
      <div className={Styles.IconWrapper}>
        <RiCalendarLine className={Styles.Icon} />
      </div>
      <div className={Styles.NavigationContent}>
        <div>
          <h4 className={Styles.Title}>工事日程を入力してください</h4>
          <div className={Styles.Schedules}>
            {constructionNumbers.map(
              (typeNumber: ConstructionScheduleType): ReactNode => {
                const schedule = deal.dealConstructionSchedules.find(
                  (s) => s.constructionScheduleType === typeNumber
                );
                if (schedule == null) {
                  return <React.Fragment key={typeNumber} />;
                }
                return (
                  <div key={typeNumber} className={Styles.ScheduleRow}>
                    {CONSTRUCTION_SCHEDULE_LABELS[typeNumber]}:
                    {formatDate(schedule.startDate)} -
                    {formatDate(schedule.endDate)}
                  </div>
                );
              }
            )}
          </div>
        </div>
        <div className={Styles.DetailLinkBlock}>
          <Button
            className={Styles.DetailLinkButton}
            onClick={handleOpen}
            variant="normal"
          >
            工事日程を入力
          </Button>
          {isOpen && (
            <ScheduleInputModal
              deal={deal}
              handleClose={handleClose}
              handleMutate={handleMutate}
              handleSubmit={handleSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};
