import React, { ReactNode } from 'react';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DropResult
} from 'react-beautiful-dnd';
import { Button, Flex } from '@itandi/itandi-bb-ui';
import { RiDownload2Line, RiFolderZipLine } from 'react-icons/ri';
import Styles from './ReportImagesAreaSp.module.scss';
import { DealReportImage } from '~/models/DealReportImage';
import { NskResponse } from '~/models/Api';
import { ReportImagesAreaSpRow } from '~/components/common/reports/ReportImagesAreaSpRow';

type Props = {
  reportImages: DealReportImage[];
  handleDownloadImage: (reportImage: DealReportImage) => void;
  handleMutateReportImages: () => Promise<void>;
  handleUpdateSeq?:
    | ((newOrderImages: DealReportImage[]) => Promise<void>)
    | null;
  handleUpdate: (submitValue: DealReportImage) => Promise<NskResponse>;
  handleDestroy?:
    | ((reportImage: DealReportImage) => Promise<NskResponse>)
    | null;
  handleDownloadPdf: () => void;
  handleDownloadZip?: () => void;
  companyType: 'management' | 'own_management' | 'vendor';
};

export const ReportImagesAreaSp: React.FC<Props> = ({
  reportImages,
  handleDownloadImage,
  handleMutateReportImages,
  handleUpdateSeq = null,
  handleUpdate,
  handleDestroy = null,
  handleDownloadPdf,
  handleDownloadZip,
  companyType
}: Props) => {
  // 並び替えのためのstate
  const [orderImages, setOrderImages] = React.useState<DealReportImage[]>([]);
  const isVendor = companyType === 'vendor';
  React.useEffect(() => {
    setOrderImages(reportImages);
  }, [reportImages]);
  const onDragEnd = (result: DropResult): void => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const moveImage =
      orderImages.find((e: DealReportImage) => String(e.id) === draggableId) ??
      null;
    const newOrderItems: DealReportImage[] = [];
    const wkOrderImages = [...orderImages];
    wkOrderImages.splice(source.index, 1);
    if (moveImage !== null) {
      wkOrderImages.splice(destination.index, 0, moveImage);
    }
    wkOrderImages.forEach((r: DealReportImage, i: number) => {
      newOrderItems.push({ ...r, seq: i + 1 });
    });
    setOrderImages(newOrderItems);
    handleUpdateSeq !== null && handleUpdateSeq(newOrderItems).then();
  };
  let tmpSeqNo = 0;
  const renderRow = (
    reportImage: DealReportImage,
    index: number
  ): ReactNode => {
    const displaySeqNo = ((): number | null => {
      if (isVendor) {
        return index + 1;
      }
      if (reportImage.outputPdfImage) {
        tmpSeqNo += 1;
        return tmpSeqNo;
      }
      return null;
    })();
    return (
      <ReportImagesAreaSpRow
        key={reportImage.id}
        companyType={companyType}
        displaySeqNo={displaySeqNo}
        handleDestroy={handleDestroy}
        handleDownloadImage={handleDownloadImage}
        handleMutateReportImages={handleMutateReportImages}
        handleUpdate={handleUpdate}
        handleUpdateSeq={handleUpdateSeq}
        index={index}
        reportImage={reportImage}
      />
    );
  };
  return (
    <div className={Styles.ReportImagesArea}>
      <Flex direction="column" gap={8} padding={16}>
        <Button
          disabled={orderImages.length <= 0}
          endIcon={<RiDownload2Line />}
          onClick={handleDownloadPdf}
          variant="secondary"
        >
          報告書ダウンロード
        </Button>
        {handleDownloadZip && (
          <Button
            disabled={orderImages.length <= 0}
            endIcon={<RiFolderZipLine />}
            onClick={handleDownloadZip}
            variant="secondary"
          >
            画像一括ダウンロード
          </Button>
        )}
      </Flex>
      <div className={Styles.ReportImageList}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: DroppableProvided): React.ReactElement<HTMLElement> => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {orderImages.map(renderRow)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};
