import React from 'react';
import {
  ComboBox,
  ComboBoxOptionType,
  CurrentFilter
} from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import { DealSearchCondition } from '~/models/Deal';
import {
  convertToComboBoxOption,
  defaultSelectedOption,
  Status
} from '~/models/Status';

type Props = {
  dealSearchCondition: DealSearchCondition;
  statuses: Status[];
};

export const StatusCombobox: React.FC<Props> = ({
  dealSearchCondition,
  statuses
}: Props) => {
  const { setValue } = useFormContext<DealSearchCondition>();
  const selectedStatuses = statuses.filter(
    ({ id }) => dealSearchCondition.statusId?.includes(id)
  );
  const [selectedStatusOptions, setSelectedStatusOptions] = React.useState<
    readonly ComboBoxOptionType[]
  >(
    selectedStatuses.length > 0
      ? selectedStatuses.map((s: Status) => convertToComboBoxOption(s))
      : defaultSelectedOption(statuses)
  );
  const onChangeStatus = React.useCallback(
    (options: readonly ComboBoxOptionType[]): void => {
      setSelectedStatusOptions(options);
      setValue(
        'statusId',
        options.map((option: ComboBoxOptionType) => Number(option.value))
      );
    },
    [setValue]
  );
  const removeStatusOption = (index: number): void => {
    const removedOptions = selectedStatusOptions.filter((_, i) => i !== index);
    setSelectedStatusOptions(removedOptions);
    setValue(
      'statusId',
      removedOptions.map((option: ComboBoxOptionType) => Number(option.value))
    );
  };
  const updatedOptions = selectedStatusOptions.map((option, index) => ({
    ...option,
    onClickClose: (): void => removeStatusOption(index)
  }));
  return (
    <>
      <ComboBox
        defaultValue={selectedStatusOptions}
        isMulti
        onChange={onChangeStatus}
        options={statuses.map((s: Status) => convertToComboBoxOption(s))}
        placeholder="ステータス"
        showOptions={false}
        value={selectedStatusOptions}
      />
      <CurrentFilter options={updatedOptions} scroll={false} />
    </>
  );
};
