import React from 'react';
import { RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { TodoTemplate } from '~/models/TodoTemplate';
import { TableRow } from '~/components/common/parts/table/TableRow';
import { useStatuses } from '~/hooks/useStatuses';
import { Dropdown } from '~/components/common/parts/dropdown/Dropdown';
import { DropdownList } from '../parts/dropdown/DropdownList';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import Styles from './TodoTemplateListRow.module.scss';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { destroy } from '~/utils/api/TodoTemplate';
import { isSuccess } from '~/utils/api/api_handler';
import { useTodoTemplates } from '~/hooks/useTodoTemplates';
import { TodoTemplateModal } from '~/components/common/todo_templates/TodoTemplateModal';
import { DealType } from '~/models/Deal';
import { Subtype } from '~/models/Subtype';

type Props = DeepReadonly<{
  dealType: DealType;
  subtype: Subtype | null;
  todoTemplate: TodoTemplate;
}>;

export const TodoTemplateListRow: React.FC<Props> = ({
  dealType,
  subtype,
  todoTemplate
}: Props) => {
  const { mutate: mutateTodoTemplates } = useTodoTemplates().useIndex();
  const {
    isOpen: openEdit,
    handleOpen: handleOpenEdit,
    handleClose: handleCloseEdit
  } = useOpenHandler();
  const {
    isOpen: openDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete
  } = useOpenHandler();
  const { data: statuses } = useStatuses().index();
  const status = React.useMemo(() => {
    if (statuses === null) {
      return null;
    }
    return statuses.find((s) => s.id === todoTemplate.statusId);
  }, [statuses, todoTemplate]);
  const handleMutate = React.useCallback(async () => {
    mutateTodoTemplates && (await mutateTodoTemplates());
  }, [mutateTodoTemplates]);
  const handleAcceptDelete = React.useCallback(async () => {
    const response = await destroy(todoTemplate.id);
    if (isSuccess(response)) {
      handleMutate && (await handleMutate());
      handleCloseDelete();
      toast.success('削除しました');
      return;
    }
    toast.error('削除に失敗しました');
  }, [handleMutate, handleCloseDelete, todoTemplate]);
  return (
    <TableRow>
      <td>{status?.name ?? ''}</td>
      <td>{todoTemplate.title}</td>
      <td>
        <Dropdown title="操作" variant="normal">
          <DropdownList>
            <DropdownMenu onClick={handleOpenEdit}>
              <span>
                <RiPencilLine className={Styles.DropdownMenuIcon} />
                編集
              </span>
            </DropdownMenu>
            <DropdownMenu onClick={handleOpenDelete}>
              <span className={Styles.DeleteLink}>
                <RiDeleteBinLine className={Styles.DropdownMenuIcon} />
                削除
              </span>
            </DropdownMenu>
          </DropdownList>
        </Dropdown>
        {openEdit && (
          <TodoTemplateModal
            dealType={dealType}
            handleClose={handleCloseEdit}
            handleMutate={handleMutate}
            subtypeId={subtype?.id ?? null}
            todoTemplate={todoTemplate}
          />
        )}
        <ConfirmModal
          confirmType="delete"
          handleAccept={handleAcceptDelete}
          handleCancel={handleCloseDelete}
          isOpen={openDelete}
        >
          削除してよろしいですか？
        </ConfirmModal>
      </td>
    </TableRow>
  );
};
