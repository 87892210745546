import { boolean, object, string } from 'yup';
import { Staff } from '~/models/Staff';
import { CompanyVendor } from './CompanyVendor';
import { Subtype } from '~/models/Subtype';
import { Property } from '~/models/kensaku_kun/property';
import { DealFormRepairParam } from './DealRepair';
import { Deal } from '~/models/Deal';

export type DealParam = {
  useProperty: boolean;
  dealType: 'restoration' | 'repair' | 'other' | null;
  dealSubtype: Subtype | null;
  roomName: string | null;
  roomNumber: string | null;
  address: string | null;
  enableAutoLock: 'true' | 'false' | null;
  enableElevator: 'true' | 'false' | null;
  conductFieldSurvey: 'true' | 'false' | null;
  placeKeyStoring: string;
  freeParking: string;
  floorMap: File | null;
  leaseContractFile1: File | null;
  leaseContractFile2: File | null;
  leaseContractFile3: File | null;
  dealRepair: DealFormRepairParam | null;
  tenantStatus: 'resident' | 'leaved';
  tenantName: string | null;
  tenantNameKana: string | null;
  tenantTel: string | null;
  tenantEmail: string | null;
  terminationDate: string | null;
  inspectionCandidateDate1: string | null;
  inspectionCandidateDateTime1: string;
  inspectionCandidateDate2: string | null;
  inspectionCandidateDateTime2: string;
  inspectionCandidateDate3: string | null;
  inspectionCandidateDateTime3: string;
  staff: Staff | null;
  companyVendor: CompanyVendor | null;
  showTenant: boolean;
  skipEstimation: boolean;
  estimateAddressName: string;
  note: string;
  property: Property<'room' | 'building'> | null;
  preferredDate: string | null;
};

export const buildDealParam = (useProperty: boolean): DealParam => ({
  useProperty,
  dealType: null,
  dealSubtype: null,
  roomName: '',
  roomNumber: '',
  address: '',
  enableAutoLock: null,
  enableElevator: null,
  conductFieldSurvey: null,
  placeKeyStoring: '',
  freeParking: '',
  floorMap: null,
  leaseContractFile1: null,
  leaseContractFile2: null,
  leaseContractFile3: null,
  dealRepair: {
    requestContent: '',
    itemName: '',
    itemCategory: '',
    manufacturer: '',
    modelNumber: '',
    modelNumberFile: null
  },
  tenantStatus: 'resident',
  tenantName: '',
  tenantNameKana: '',
  tenantTel: '',
  tenantEmail: '',
  terminationDate: null,
  inspectionCandidateDate1: null,
  inspectionCandidateDateTime1: '',
  inspectionCandidateDate2: null,
  inspectionCandidateDateTime2: '',
  inspectionCandidateDate3: null,
  inspectionCandidateDateTime3: '',
  staff: null,
  companyVendor: null,
  showTenant: true,
  skipEstimation: false,
  estimateAddressName: '',
  note: '',
  property: null,
  preferredDate: null
});

export type DealUpdateParam = Omit<
  DealParam,
  | 'dealType'
  | 'dealSubtype'
  | 'roomName'
  | 'floorMap'
  | 'leaseContractFile1'
  | 'leaseContractFile2'
  | 'leaseContractFile3'
  | 'dealRepair'
  | 'conductFieldSurvey'
  | 'inspectionCandidateDate1'
  | 'inspectionCandidateDateTime1'
  | 'inspectionCandidateDate2'
  | 'inspectionCandidateDateTime2'
  | 'inspectionCandidateDate3'
  | 'inspectionCandidateDateTime3'
  | 'companyVendor'
  | 'showTenant'
  | 'skipEstimation'
  | 'property'
> & {
  name: string | null;
  amaterasPropertyId: number | null;
};

export const buildDealUpdateParam = (deal: Deal): DealUpdateParam => ({
  useProperty: !!deal.propertyId,
  amaterasPropertyId: deal.propertyId,
  name: deal.name,
  roomNumber: deal.roomNumber,
  address: deal.address,
  enableAutoLock: deal.enableAutoLock ? 'true' : 'false',
  enableElevator: deal.enableElevator ? 'true' : 'false',
  placeKeyStoring: deal.placeKeyStoring,
  freeParking: deal.freeParking,
  tenantStatus:
    deal.tenantName && deal.tenantNameKana && deal.tenantTel
      ? 'resident'
      : 'leaved',
  tenantName: deal.tenantName,
  tenantNameKana: deal.tenantNameKana,
  tenantTel: deal.tenantTel,
  tenantEmail: deal.tenantEmail,
  terminationDate: deal.terminationDate,
  staff: deal.staff,
  estimateAddressName: deal.estimateAddressName,
  note: deal.note,
  preferredDate: deal.preferredDate
});

export const requestContentPlaceholder =
  '例）\nいつから （日付）:5/30（入居時）\nどこで （場所）：キッチン\n何を（物） ：タオル掛け\nなぜ （経緯）：入居時から壊れてた\nどのように（状況）:接着剤で止めた跡があるが、片方に亀裂が入りすでに割れてる。もう一方でずっと支えてる形になってるのでいつ壊れてもおかしくない';

export const validationSchema = object().shape({
  dealType: string().nullable().required('入力してください'),
  roomName: string().when('useProperty', {
    is: false,
    then: string().required('入力してください')
  }),
  roomNumber: string().when('useProperty', {
    is: false,
    then: string().required('入力してください')
  }),
  address: string().when('useProperty', {
    is: false,
    then: string().required('入力してください')
  }),
  enableAutoLock: boolean().nullable().required('入力してください'),
  enableElevator: boolean().nullable().required('入力してください'),
  conductFieldSurvey: boolean()
    .nullable()
    .when('dealType', {
      is: 'repair',
      then: boolean().nullable().required('入力してください')
    }),
  dealRepair: object()
    .nullable()
    .when('dealType', {
      is: 'repair',
      then: object().shape({
        requestContent: string().required('入力してください').nullable(),
        itemCategory: string().required('入力してください').nullable()
      })
    }),
  tenantName: string().when('tenantStatus', {
    is: 'resident',
    then: string().required('入力してください')
  }),
  tenantNameKana: string().when('tenantStatus', {
    is: 'resident',
    then: string().required('入力してください')
  }),
  tenantTel: string()
    .when('tenantStatus', {
      is: 'resident',
      then: string().required('入力してください')
    })
    .nullable()
    .matches(/^([-\d]*)?$/, {
      message: '電話番号は数字とハイフンのみで入力してください'
    })
    .matches(/^(0\d+-?\d+-?\d+)?$/, {
      message: '電話番号の形式を確認してください'
    })
    .test(
      'PhoneNumber',
      '電話番号は10桁または11桁で入力してください(-は数えません)',
      (val: string | undefined | null) =>
        [0, 10, 11].includes((val ?? '').replace(/-/g, '').length)
    ),
  tenantEmail: string()
    .nullable()
    .email('メールアドレスの形式を確認してください'),
  staff: object().nullable().required('入力してください'),
  companyVendor: object().nullable().required('入力してください'),
  estimateAddressName: string().required('入力してください'),
  property: object()
    .nullable()
    .when('useProperty', {
      is: true,
      then: object().nullable().required('入力してください')
    })
});
export const updateValidationSchema = object().shape({
  name: string().when('useProperty', {
    is: false,
    then: string().required('入力してください'),
    otherwise: string().strip()
  }),
  roomNumber: string().when('useProperty', {
    is: false,
    then: string().required('入力してください'),
    otherwise: string().strip()
  }),
  address: string().when('useProperty', {
    is: false,
    then: string().required('入力してください'),
    otherwise: string().strip()
  }),
  enableAutoLock: boolean().nullable().required('入力してください'),
  enableElevator: boolean().nullable().required('入力してください'),
  tenantName: string().when('tenantStatus', {
    is: 'resident',
    then: string().required('入力してください')
  }),
  tenantNameKana: string().when('tenantStatus', {
    is: 'resident',
    then: string().required('入力してください')
  }),
  tenantTel: string()
    .when('tenantStatus', {
      is: 'resident',
      then: string().required('入力してください')
    })
    .nullable()
    .matches(/^([-\d]*)?$/, {
      message: '電話番号は数字とハイフンのみで入力してください'
    })
    .matches(/^(0\d+-?\d+-?\d+)?$/, {
      message: '電話番号の形式を確認してください'
    })
    .test(
      'PhoneNumber',
      '電話番号は10桁または11桁で入力してください(-は数えません)',
      (val: string | undefined | null) =>
        [0, 10, 11].includes((val ?? '').replace(/-/g, '').length)
    ),
  tenantEmail: string()
    .nullable()
    .email('メールアドレスの形式を確認してください'),
  staff: object().nullable().required('入力してください'),
  estimateAddressName: string().required('入力してください')
});
