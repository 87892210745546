import { NskResponse } from '~/models/Api';
import { postRequest, putRequest } from '~/utils/api/api_handler';
import { DealVendorCompany } from '~/models/DealVendorCompany';

const paths = {
  create: '/vendors/deals/:deal_id/deal_vendor_companies',
  update: '/vendors/deals/:deal_id/deal_vendor_companies/:id'
};

export const create = async (
  dealId: number,
  vendorCompanyId: number
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    vendorCompanyId
  });

export const update = async (
  dealId: number,
  dealVendorCompany: DealVendorCompany,
  vendorUserId: number
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update.replace(
      ':deal_id',
      String(dealId).replace(':id', String(dealVendorCompany.id))
    ),
    {
      vendorUserId
    }
  );
