import React from 'react';
import cx from 'clsx';
import { RiArrowDownSLine } from 'react-icons/ri';
import Styles from './SearchDropdown.module.scss';
import { DealSearchCondition } from '~/models/Deal';
import { useCompanySettings } from '~/hooks/vendors/useCompanySettings';
import { DealSearchCompanyIdList } from './DealSearchCompanyIdList';
import { SimpleDropdown } from '~/components/common/parts/dropdown/SimpleDropdown';

type Props = {
  condition: DealSearchCondition;
  handleChangeCondition: (condition: DealSearchCondition) => Promise<void>;
};

export const DealSearchCompanyId: React.FC<Props> = ({
  condition,
  handleChangeCondition
}: Props) => {
  const [checkId, setCheckId] = React.useState<number | null>(
    condition?.companyMasterId ?? null
  );
  const [searchText, setSearchText] = React.useState<string>('');
  const { data } = useCompanySettings().index(searchText, null);
  const { data: companySetting } = useCompanySettings().show(checkId);
  const handleChangeSearchText = React.useCallback((searchWord: string) => {
    setSearchText(searchWord);
  }, []);
  const onClickMenu = (companyMasterId: number): void => {
    if (checkId === companyMasterId) {
      // 解除
      setCheckId(null);
      const { companyMasterId: _oldId, ...removedCondition } = condition;
      handleChangeCondition(removedCondition).then();
      return;
    }
    setCheckId(companyMasterId);
    handleChangeCondition({
      ...condition,
      companyMasterId
    }).then();
  };
  const isSelected = checkId !== null;
  if (searchText === '' && data !== null && data.objects.length === 0) {
    // 提携中の管理会社がいない場合は表示しない
    return null;
  }
  return (
    <div className={Styles.DropdownWrapper}>
      <SimpleDropdown
        className={cx(Styles.Dropdown, isSelected && Styles.Selected)}
        placement="bottom-start"
        title={
          <span className={Styles.DropdownTitle}>
            {isSelected && companySetting ? (
              <span>
                <span className={Styles.SelectedTitle}>管理会社:</span>
                {companySetting.name}
              </span>
            ) : (
              '管理会社'
            )}
            <RiArrowDownSLine className={Styles.Caret} />
          </span>
        }
      >
        <DealSearchCompanyIdList
          checkId={checkId}
          handleChangeSearchText={handleChangeSearchText}
          onClickMenu={onClickMenu}
          searchText={searchText}
        />
      </SimpleDropdown>
    </div>
  );
};
