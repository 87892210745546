import React, { ReactNode } from 'react';
import cx from 'clsx';
import { RiArrowDownSLine } from 'react-icons/ri';
import Styles from './SearchDropdown.module.scss';
import { DropdownList } from '~/components/common/parts/dropdown/DropdownList';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';
import { DealSearchCondition } from '~/models/Deal';
import { SimpleDropdown } from '~/components/common/parts/dropdown/SimpleDropdown';
import { useVendorUsers } from '~/hooks/vendors/useVendorUsers';
import { VendorUser } from '~/models/VendorUser';

type Props = {
  condition: DealSearchCondition;
  handleChangeCondition: (condition: DealSearchCondition) => Promise<void>;
};

type MenuProps = {
  vendorUser: VendorUser;
  onClickMenu: (vendorUserId: number) => void;
  isActive: boolean;
};
const VendorUserMenu: React.FC<MenuProps> = ({
  vendorUser,
  onClickMenu,
  isActive
}: MenuProps) => (
  <DropdownMenu
    className={cx(isActive && Styles.ActiveRow)}
    onClick={(): void => {
      onClickMenu(vendorUser.id);
    }}
  >
    {isActive && <span className={Styles.ActiveRowLabel}>[選択中]</span>}
    {vendorUser.name}
  </DropdownMenu>
);

export const DealSearchVendorUserId: React.FC<Props> = ({
  condition,
  handleChangeCondition
}: Props) => {
  const [checkId, setCheckId] = React.useState<number | null>(
    condition?.userId ?? null
  );
  const { data: vendorUsers } = useVendorUsers().index();
  const selectedName: string = React.useMemo((): string => {
    if (vendorUsers == null || checkId === null) {
      return '';
    }
    return (
      vendorUsers.find((u: VendorUser) => u.id === Number(checkId))?.name ?? ''
    );
  }, [vendorUsers, checkId]);
  const onClickMenu = React.useCallback(
    (userId: number): void => {
      if (checkId === userId) {
        // 解除
        setCheckId(null);
        const { userId: _oldId, ...removedCondition } = condition;
        handleChangeCondition(removedCondition).then();
        return;
      }
      setCheckId(userId);
      handleChangeCondition({
        ...condition,
        userId
      }).then();
    },
    [checkId, condition, handleChangeCondition]
  );
  const renderRow = React.useCallback(
    (vendorUser: VendorUser): ReactNode => {
      const isActive = vendorUser.id === checkId;
      return (
        <VendorUserMenu
          key={vendorUser.id}
          isActive={isActive}
          onClickMenu={onClickMenu}
          vendorUser={vendorUser}
        />
      );
    },
    [checkId, onClickMenu]
  );
  const isSelected = checkId !== null;
  return (
    <div className={Styles.DropdownWrapper}>
      <SimpleDropdown
        className={cx(Styles.Dropdown, isSelected && Styles.Selected)}
        placement="bottom-start"
        title={
          <span className={Styles.DropdownTitle}>
            {isSelected ? (
              <span>
                <span className={Styles.SelectedTitle}>担当者：</span>
                {selectedName}
              </span>
            ) : (
              '担当者'
            )}
            <RiArrowDownSLine className={Styles.Caret} />
          </span>
        }
      >
        <DropdownList>
          <div className={Styles.ListBlock}>
            <div className={Styles.ListArea}>
              {vendorUsers !== null && vendorUsers.map(renderRow)}
            </div>
          </div>
        </DropdownList>
      </SimpleDropdown>
    </div>
  );
};
