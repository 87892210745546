import { number, object } from 'yup';
import {
  DealConstructionBase,
  DealConstructionContextBase
} from '~/models/DealConstruction';

export type BillingType = 'owner' | 'rentee';
export type DealBilling = DealConstructionBase & {
  type: 'DealBilling';
  billingType: BillingType;
};

export type DealBillingContext = DealConstructionContextBase & {
  type: 'DealBillingContext';
};

export type BillingBulkUpdateContext = {
  scale: number;
  truncateScale: number;
};

export const buildDefaultContext = (): BillingBulkUpdateContext => ({
  scale: 1.2,
  truncateScale: 2
});

export const validationSchemeBulkUpdateContext = object().shape({
  scale: number()
    .min(0, '0以上で入力してください')
    .max(2, '2以下で入力してください')
    .required('倍率を入力してください')
});
