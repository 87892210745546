import React from 'react';
import { RiCamera3Line } from 'react-icons/ri';
import Styles from '../DealCard.module.scss';
import { formatDate } from '~/utils/DateUtil';
import { EditMenuButton } from '~/components/common/parts/button/EditMenuButton';
import { ScheduleInputModal } from '~/components/common/inspection_schedule/ScheduleInputModal';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { NskResponse } from '~/models/Api';
import { update } from '~/utils/api/vendors/DealInspection';
import { useDealInspectionReportImages } from '~/hooks/vendors/deals/useDealInspectionReportImages';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useSessions } from '~/hooks/vendors/useSessions';
import { matchDealType } from '~/models/Deal';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { useVendorUsers } from '~/hooks/vendors/useVendorUsers';
import { InspectionCandidateDate } from '~/models/InspectionCandidateDate';

export const DealInspectionVendorCard: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(id);
  const { data: vendorUsers } = useVendorUsers().index();
  const { data: session } = useSessions().index();
  const reportsApi = useDealInspectionReportImages();
  const { data: reportImages } = reportsApi.index(id);
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  if (vendorUsers === null || reportImages === null || deal === null) {
    return null;
  }
  const { dealInspectionCandidateDates: candidateDates } = deal;
  const handleUpdate = async (
    inspectionDate: Date,
    userId: number | null
  ): Promise<NskResponse<void>> => update(deal.id, inspectionDate, userId);
  const handleMutate = async (): Promise<void> => {
    mutateDeal && (await mutateDeal());
  };
  return (
    <div className={Styles.DealCard}>
      <h3 className={Styles.Title}>
        <RiCamera3Line className={Styles.TitleIcon} />
        <span>
          {`${matchDealType(deal, 'restoration') ? '退去' : '現場'}立会`}
        </span>
        {reportImages.length > 0 ? (
          <span className={Styles.InspectedLabel}>実施済</span>
        ) : (
          <span className={Styles.YetInspectLabel}>未実施</span>
        )}
        <div className={Styles.EditButtonWrapper}>
          <EditMenuButton onClick={handleOpen}>変更</EditMenuButton>
          {isOpen && (
            <ScheduleInputModal
              deal={deal}
              handleClose={handleClose}
              handleMutate={handleMutate}
              handleUpdate={handleUpdate}
              session={session}
              vendorUsers={vendorUsers}
            />
          )}
        </div>
      </h3>
      <div className={Styles.Content}>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>
            {`${matchDealType(deal, 'restoration') ? '退去' : '現場'}立会`}
          </dt>
          <dd className={Styles.ListContentValue}>
            {deal.inspectionDate == null ? (
              <span className={Styles.NotYet}>未定</span>
            ) : (
              <span>{formatDate(deal.inspectionDate, true)}</span>
            )}
          </dd>
        </dl>
        {candidateDates.map(
          (candidateDate: InspectionCandidateDate): React.ReactNode => (
            <dl key={candidateDate.id} className={Styles.ListContent}>
              <dt className={Styles.ListContentCaption}>
                第{candidateDate.sort}希望
              </dt>
              <dd className={Styles.ListContentValue}>
                <span className={Styles.Date}>
                  {formatDate(candidateDate.date)}
                </span>
                {candidateDate.time}
              </dd>
            </dl>
          )
        )}
      </div>
    </div>
  );
};
