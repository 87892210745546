import React from 'react';
import Styles from './DealRowContent.module.scss';
import { DealIndexVendor, matchDealType } from '~/models/Deal';
import { formatDate } from '~/utils/DateUtil';
import { processedHistory } from '~/models/DealHistory';

type Props = {
  deal: DealIndexVendor;
};

export const DealRowContent: React.FC<Props> = ({ deal }: Props) => (
  <>
    <div className={Styles.RowHeader}>
      <div>実施会社</div>
      <div>管理会社</div>
      <div>
        <div>入居者氏名</div>
        <div>（カナ）</div>
      </div>
      <div>
        <div>立会日時</div>
        {matchDealType(deal, 'restoration') && <div>解約日</div>}
      </div>
      <div>
        <div>見積の提出</div>
        <div>完了画像の提出</div>
      </div>
      <div>
        <div>登録日</div>
        <div>完了日</div>
      </div>
    </div>
    <div className={Styles.RowContent}>
      <div>
        {deal.subVendorCompany === null ? (
          <span className={Styles.DataEmpty}>未選択</span>
        ) : (
          <span>{deal.subVendorCompany.name}</span>
        )}
      </div>
      <div>
        <div>{deal.companySetting.name} </div>
        <div>
          {deal.staff === null ? (
            <span className={Styles.DataEmpty}>未登録</span>
          ) : (
            <span>{deal.staff?.name}</span>
          )}
        </div>
      </div>
      <div>
        <div>
          {deal.tenantName === null ? (
            <span className={Styles.DataEmpty}>未登録</span>
          ) : (
            deal.tenantName
          )}
        </div>
        {deal.tenantNameKana != null && <div>{deal.tenantNameKana}</div>}
      </div>
      <div>
        <div>
          {deal.inspectionDate === null ? (
            <span className={Styles.DataEmpty}>未登録</span>
          ) : (
            <span>{formatDate(deal.inspectionDate, true)}</span>
          )}
        </div>
        {matchDealType(deal, 'restoration') && (
          <div>
            {deal.terminationDate === null ? (
              <span className={Styles.DataEmpty}>未登録</span>
            ) : (
              <span>{formatDate(deal.terminationDate)}</span>
            )}
          </div>
        )}
      </div>
      <div>
        <div>
          {processedHistory(deal, 'estimations_created') ? (
            <span>登録済み</span>
          ) : (
            <span className={Styles.DataEmpty}>未登録</span>
          )}
        </div>
        <div>
          {processedHistory(deal, 'completion_report_created') ? (
            <span>登録済み</span>
          ) : (
            <span className={Styles.DataEmpty}>未登録</span>
          )}
        </div>
      </div>
      <div>
        <div>{formatDate(deal.createdAt)}</div>
        <div>
          {deal.completedDate ? (
            <span>{formatDate(deal.completedDate)}</span>
          ) : (
            <span className={Styles.DataEmpty}>未登録</span>
          )}
        </div>
      </div>
    </div>
  </>
);
