import React, { ReactNode } from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import cx from 'clsx';
import { toast } from 'react-toastify';
import Styles from './NotificationList.module.scss';
import { Status } from '~/models/Status';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { NotificationListRow } from '~/components/common/notifications/settings/NotificationListRow';
import { Notification, NotificationEdit } from '~/models/Notification';
import { NotificationModal } from '~/components/common/notifications/settings/NotificationModal';
import { NskResponse } from '~/models/Api';
import { isSuccess } from '~/utils/api/api_handler';
import { Subtype } from '~/models/Subtype';

type Props = DeepReadonly<{
  type: 'CompanyNotification' | 'VendorNotification';
  notifications: Notification[];
  statuses: Status[];
  subtypes?: Subtype[] | null;
  handleMutate: () => Promise<void>;
  handleCreate: (notification: NotificationEdit) => Promise<NskResponse<void>>;
  handleUpdate: (
    notification: NotificationEdit,
    notificationId: number
  ) => Promise<NskResponse<void>>;
  handleDestroy: (notification: Notification) => Promise<NskResponse<void>>;
  usePartner?: boolean;
}>;

export const NotificationList: React.FC<Props> = ({
  type,
  notifications,
  statuses,
  subtypes = null,
  handleMutate,
  handleCreate,
  handleUpdate,
  handleDestroy,
  usePartner = false
}: Props) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const renderRow = React.useCallback(
    (notification: Notification): ReactNode => (
      <NotificationListRow
        key={notification.id}
        handleDestroy={handleDestroy}
        handleMutate={handleMutate}
        handleUpdate={handleUpdate}
        notification={notification}
        statuses={statuses}
        subtypes={subtypes}
        type={type}
        usePartner={usePartner}
      />
    ),
    [
      type,
      handleDestroy,
      handleMutate,
      handleUpdate,
      statuses,
      subtypes,
      usePartner
    ]
  );
  const handleAcceptSubmit = React.useCallback(
    async (notification: NotificationEdit): Promise<void> => {
      const response = await handleCreate(notification);
      if (isSuccess(response)) {
        await handleMutate();
        handleClose();
        toast.success('通知設定を追加しました。');
        return;
      }
      toast.error('通知設定の追加に失敗しました。');
    },
    [handleMutate, handleClose, handleCreate]
  );
  return (
    <div>
      <table className={Styles.List}>
        <thead>
          <tr className={cx(Styles.ListRow, Styles.Header)}>
            <th>案件種別</th>
            <th>通知トリガー</th>
            <th>案件状況</th>
            <th>通知タイプ</th>
            <th>通知先</th>
            <th>通知タイトル</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>{notifications.map(renderRow)}</tbody>
      </table>
      <Button height="default" onClick={handleOpen} variant="secondary">
        通知設定の追加
      </Button>
      {isOpen && (
        <NotificationModal
          handleAcceptSubmit={handleAcceptSubmit}
          handleClose={handleClose}
          statuses={statuses}
          subtypes={subtypes}
          type={type}
          usePartner={usePartner}
        />
      )}
    </div>
  );
};
