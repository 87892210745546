import React from 'react';
import {
  OWN_FINISHED,
  OWN_IN_CONSTRUCTION,
  OWN_IN_ESTIMATION,
  ownStatusMovingValidation
} from '~/models/Status';
import Styles from './statuses.module.scss';
import useItandiAccount from '~/hooks/useItandiAccount';
import { ReportImageNavigation } from '~/components/common/reports/navigation/ReportImageNavigation';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { useDeals } from '~/hooks/useDeals';
import { update as updateDealConstructionSchedule } from '~/utils/api/deals/DealConstructionSchedule';
import { useDealCompletionReportImages } from '~/hooks/deals/useDealCompletionReportImages';
import { PATH as COMPLETE_REPORT_PATH } from '~/pages/deals/[id]/completion_reports';
import { OwnStatusHandleButtons } from '~/components/common/statuses/OwnStatusHandleButtons';
import { ScheduleInputNavigation } from '~/components/common/construction_schedule/ScheduleInputNavigation';
import { DealConstructionScheduleEdit } from '~/models/DealConstructionSchedule';
import { NskResponse } from '~/models/Api';

export const OwnInConstruction: React.FC = () => {
  const { data: account } = useItandiAccount().currentAccount();
  const dealId = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(dealId);
  const { data: completeImages } =
    useDealCompletionReportImages().index(dealId);
  const statusDisabled = React.useMemo(() => {
    if (account === null) return true;
    return !ownStatusMovingValidation[OWN_IN_CONSTRUCTION](account);
  }, [account]);
  if (deal === null || completeImages === null) {
    return null;
  }
  const handleMutateDeal = async (): Promise<void> => {
    mutateDeal && (await mutateDeal());
  };
  const handleSubmitDealConstructionSchedule = async (
    schedules: DealConstructionScheduleEdit[]
  ): Promise<NskResponse<void>> =>
    updateDealConstructionSchedule(deal.id, schedules);
  return (
    <div>
      <h3 className={Styles.Title}>工事中です</h3>
      <p className={Styles.Description}>
        工事完了後にステータスを進めてください
      </p>
      <ScheduleInputNavigation
        deal={deal}
        handleMutate={handleMutateDeal}
        handleSubmit={handleSubmitDealConstructionSchedule}
      />
      <ReportImageNavigation
        detailPageButtonLabel="画像登録画面"
        detailPagePath={COMPLETE_REPORT_PATH(deal.id)}
        optionalLabel
        reportImages={completeImages}
        title="完了報告画像を登録してください"
      />
      <OwnStatusHandleButtons
        backwardDisabled={statusDisabled}
        forwardDisabled={statusDisabled}
        // TODO: nextStatusId, prevStatusIdを修正する
        nextStatusId={OWN_FINISHED}
        prevStatusId={OWN_IN_ESTIMATION}
      />
    </div>
  );
};
