import React from 'react';
import Styles from './DealMainWorkflow.module.scss';
import { StatusBar } from '~/components/common/parts/status/StatusBar';
import { useStatuses } from '~/hooks/vendors/useStatuses';
import { StatusSwitcher } from '~/components/specifics/vendors/deals/[id]/statuses/StatusSwitcher';
import {
  getWithVendorStatuses,
  isCancelApplying,
  isCanceled,
  NOW_ACCEPTING
} from '~/models/Status';
import { DealMainWorkflowVendorCancel } from './DealMainWorkflowVendorCancel';
import { DealMainWorkflowVendorSub } from '~/components/common/deals/show/main/DealMainWorkflowVendorSub';
import { getOriginVendor, isOriginVendor } from '~/models/DealVendorCompany';
import { useSessions } from '~/hooks/vendors/useSessions';
import { StatusReasonCard } from '~/components/common/statuses/StatusReasonCard';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const DealMainWorkflowVendor: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: statuses } = useStatuses().index();
  const { data: session } = useSessions().index();
  if (deal === null || session === null) {
    return null;
  }
  const isSubVendor = !isOriginVendor(deal, session.vendorCompany.id);
  if (!isSubVendor && isCancelApplying(deal)) {
    return <DealMainWorkflowVendorCancel />;
  }
  if (statuses === null) {
    return <></>;
  }
  const displayStatuses = getWithVendorStatuses(statuses);
  return (
    <div>
      {!isCanceled(deal.status) && (
        <>
          <div className={Styles.StatusCard}>
            <h2 className={Styles.Header}>
              {deal.companySetting.name}からの依頼
            </h2>
            {isSubVendor && (
              <h4 className={Styles.SubVendorLabel}>
                施工会社：{getOriginVendor(deal)?.name}
              </h4>
            )}
            <StatusBar currentId={deal.status} statuses={displayStatuses} />
          </div>
          {deal.status !== NOW_ACCEPTING && <DealMainWorkflowVendorSub />}
        </>
      )}
      <StatusReasonCard deal={deal} />
      <div className={Styles.WorkflowCard}>
        <StatusSwitcher statusId={deal.status} />
      </div>
    </div>
  );
};
