import React from 'react';
import { useRouter } from 'next/router';
import { Page as Component } from '~/components/specifics/vendors/deals/Page';
import { useSessions } from '~/hooks/vendors/useSessions';
import { Page as ComponentV2 } from '~/components/specifics/vendors/deals/index/Page';

export const Page: React.FC = () => {
  const router = useRouter();
  const { isLoading } = useSessions().index();
  if (isLoading || !router.isReady) {
    return <></>;
  }
  const { l5ph2fch7r: V2 = null } = router.query;
  return V2 ? <ComponentV2 /> : <Component />;
};
