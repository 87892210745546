import React, { ReactNode } from 'react';
import { Button, InputRadio } from '@itandi/itandi-bb-ui';
import Styles from './DealClientTodoTable.module.scss';
import { useDealTodos } from '~/hooks/deals/useDealTodos';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { DealTodo } from '~/models/DealTodo';
import { DealClientTodoTableRow } from '~/components/common/deals/show/main/todos/DealClientTodoTableRow';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { DealClientTodoModal } from '~/components/common/deals/show/main/todos/DealClientTodoModal';
import { update } from '~/utils/api/deals/DealTodo';

export const DealClientTodoTable: React.FC = () => {
  const id = useIdQueryHandler();
  const [showMode, setShowMode] = React.useState<'all' | 'undone'>('undone');
  const { data: todos, mutate: mutateTodos } = useDealTodos().index(id);
  const [showTodos, setShowTodos] = React.useState<DealTodo[] | null>(null);
  React.useEffect(() => {
    if (todos === null) {
      return;
    }
    setShowTodos(
      showMode === 'all' ? todos : todos.filter((todo) => !todo.completed)
    );
  }, [todos, showMode]);

  const handleCheck = React.useCallback(
    (newTodo: DealTodo): void => {
      if (id === null || showTodos === null) return;
      update(id, newTodo.id, newTodo).then();
      const targetIndex = showTodos.findIndex(
        (t: DealTodo) => t.id === newTodo.id
      );
      const newTodos = [...showTodos];
      newTodos.splice(targetIndex, 1, newTodo);
      setShowTodos(newTodos);
    },
    [id, showTodos]
  );

  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const handleMutate = React.useCallback(async () => {
    mutateTodos && (await mutateTodos());
  }, [mutateTodos]);
  const renderRow = React.useCallback(
    (todo: DealTodo): ReactNode => (
      <DealClientTodoTableRow
        key={todo.id}
        handleCheck={handleCheck}
        todo={todo}
      />
    ),
    [handleCheck]
  );
  if (todos === null) {
    return null;
  }
  return (
    <div>
      <div className={Styles.RadioGroup}>
        <InputRadio
          checked={showMode === 'undone'}
          label="未完了のみ表示"
          name="showMode"
          onChange={async (): Promise<void> => {
            mutateTodos && (await mutateTodos());
            setShowMode('undone');
          }}
          value="undone"
        />
        <InputRadio
          checked={showMode === 'all'}
          label="すべて表示"
          name="showMode"
          onChange={async (): Promise<void> => {
            mutateTodos && (await mutateTodos());
            setShowMode('all');
          }}
          value="all"
        />
      </div>
      <table className={Styles.TodoTable}>
        <thead>
          <tr className={Styles.TodoTableHeaderRow}>
            <th className={Styles.CheckColumn}>完了</th>
            <th>タイトル</th>
            <th className={Styles.DueDateColumn}>対応期限</th>
            <th className={Styles.CompletedAtColumn}>完了日</th>
            <th>操作</th>
          </tr>
        </thead>
        {showTodos !== null && <tbody>{showTodos.map(renderRow)}</tbody>}
      </table>
      {showTodos !== null && showTodos.length === 0 ? (
        <div className={Styles.EmptyPanel}>
          <p className={Styles.EmptyMessage}>TODOはありません</p>
          <Button height="small" onClick={handleOpen} variant="tertiary">
            TODOを追加
          </Button>
        </div>
      ) : (
        <div className={Styles.AddButtonArea}>
          <Button height="small" onClick={handleOpen} variant="tertiary">
            TODOを追加
          </Button>
        </div>
      )}
      {isOpen && (
        <DealClientTodoModal
          handleClose={handleClose}
          handleMutate={handleMutate}
        />
      )}
    </div>
  );
};
