import React, { ReactNode } from 'react';
import cx from 'clsx';
import { RiArrowDownSLine } from 'react-icons/ri';
import { Badge, InputCheckBox } from '@itandi/itandi-bb-ui';
import Styles from './DealSearchStatusId.module.scss';
import { DropdownList } from '~/components/common/parts/dropdown/DropdownList';
import { getWithVendorStatuses, Status } from '~/models/Status';
import { DealSearchCondition } from '~/models/Deal';
import DropdownStyles from './SearchDropdown.module.scss';
import { SimpleDropdown } from '~/components/common/parts/dropdown/SimpleDropdown';

type Props = {
  statuses: Status[];
  condition: DealSearchCondition;
  handleChangeCondition: (condition: DealSearchCondition) => Promise<void>;
  isVendor: boolean;
};

const isActive = (statusId: number[] | undefined): boolean => {
  if (statusId === undefined) {
    return true;
  }
  if (Array.isArray(statusId)) {
    return statusId.length > 0;
  }
  return true;
};

export const DealSearchStatusId: React.FC<Props> = ({
  statuses,
  condition,
  handleChangeCondition,
  isVendor
}: Props) => {
  const [checkActive, setCheckActive] = React.useState<boolean>(
    isActive(condition.statusId)
  );
  const [checkStatuses, setCheckStatuses] = React.useState<number[]>([]);

  const updateCheckStatuses = (statusId: number): number[] => {
    if (checkStatuses.includes(statusId)) {
      const newCheckStatuses = checkStatuses.filter(
        (i: number) => i !== statusId
      );
      setCheckStatuses(newCheckStatuses);
      return newCheckStatuses;
    }
    const newCheckStatuses = [...checkStatuses];
    newCheckStatuses.push(statusId);
    setCheckStatuses(newCheckStatuses);
    return newCheckStatuses;
  };

  const handleCheckStatus = (statusId: number): void => {
    const newCheckStatuses = updateCheckStatuses(statusId);
    if (newCheckStatuses.length > 0 && checkActive) {
      setCheckActive(false);
    }
    if (newCheckStatuses.length === 0) {
      setCheckActive(true);
    }
    handleChangeCondition({
      ...condition,
      statusId: newCheckStatuses
    }).then();
  };
  const handleCheckActive = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    setCheckActive(checked);
    const newCheckStatuses = ((): number[] => {
      if (checked) {
        // すべての進行中の案件を選択したら個別の条件指定をクリア
        return [];
      }
      return condition.statusId == null ? [] : [...condition.statusId];
    })();
    setCheckStatuses(newCheckStatuses);
    handleChangeCondition({
      ...condition,
      statusId: newCheckStatuses
    }).then();
  };
  const onClickCancel = (): void => {
    setCheckActive(true);
    setCheckStatuses([]);
    const { statusId: _statusId, ...removedCondition } = condition;
    handleChangeCondition(removedCondition).then();
  };
  const isSelected = checkStatuses.length > 0;
  if (statuses === null) {
    return null;
  }
  const withVendorStatuses = getWithVendorStatuses(statuses);
  const ownStatuses = statuses.filter((s: Status) => s.useType === 'own');
  const renderStatusCheckbox = (status: Status): ReactNode => (
    <div key={status.id} className={Styles.DropdownCheck}>
      <InputCheckBox
        checked={checkStatuses.includes(status.id)}
        labelText={status.name}
        onChange={(): void => {
          handleCheckStatus(status.id);
        }}
      />
    </div>
  );
  return (
    <div className={DropdownStyles.DropdownWrapper}>
      <SimpleDropdown
        className={cx(
          DropdownStyles.Dropdown,
          isSelected && DropdownStyles.Selected
        )}
        placement="bottom-start"
        title={
          <span className={DropdownStyles.DropdownTitle}>
            ステータス
            {checkStatuses.length > 0 && (
              <span className={Styles.BadgeWrapper}>
                <Badge count={checkStatuses.length} />
              </span>
            )}
            <RiArrowDownSLine className={DropdownStyles.Caret} />
          </span>
        }
      >
        <DropdownList>
          <div className={Styles.DropdownCheck}>
            <InputCheckBox
              checked={checkActive}
              labelText="すべての進行中の案件"
              onChange={handleCheckActive}
            />
          </div>
          <div className={Styles.StatusesLabel}>施工会社依頼ステータス</div>
          {withVendorStatuses.map(renderStatusCheckbox)}
          {!isVendor && (
            <>
              <div className={Styles.StatusesLabel}>自社管理ステータス</div>
              {ownStatuses.map(renderStatusCheckbox)}
            </>
          )}
          <div className={Styles.DropdownClear}>
            <button
              className={Styles.ClearCheckButton}
              onClick={onClickCancel}
              type="button"
            >
              選択を解除
            </button>
          </div>
        </DropdownList>
      </SimpleDropdown>
    </div>
  );
};
