import React, { ReactNode } from 'react';
import cx from 'clsx';
import { RiArrowDownSLine } from 'react-icons/ri';
import Styles from './SearchDropdown.module.scss';
import { DropdownList } from '~/components/common/parts/dropdown/DropdownList';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';
import { DealSearchCondition } from '~/models/Deal';
import { useVendorPartners } from '~/hooks/vendors/useVendorPartners';
import { VendorPartner } from '~/models/VendorPartner';
import { SimpleDropdown } from '~/components/common/parts/dropdown/SimpleDropdown';
import { VendorCompany } from '~/models/VendorCompany';

type Props = {
  condition: DealSearchCondition;
  handleChangeCondition: (condition: DealSearchCondition) => Promise<void>;
};

const UN_ASSIGNED = -1;

type MenuProps = {
  partnerVendorCompany: VendorCompany | null;
  onClickMenu: (partnerVendorId: number) => void;
  isActive: boolean;
};
const PartnerMenu: React.FC<MenuProps> = ({
  partnerVendorCompany,
  onClickMenu,
  isActive
}: MenuProps) => {
  if (partnerVendorCompany === null) {
    return null;
  }
  return (
    <DropdownMenu
      className={cx(isActive && Styles.ActiveRow)}
      onClick={(): void => {
        onClickMenu(partnerVendorCompany.id);
      }}
    >
      {isActive && <span className={Styles.ActiveRowLabel}>[選択中]</span>}
      {partnerVendorCompany.name}
    </DropdownMenu>
  );
};

export const DealSearchPartnerVendor: React.FC<Props> = ({
  condition,
  handleChangeCondition
}: Props) => {
  const [checkId, setCheckId] = React.useState<number | null>(
    condition?.partnerVendorId ?? null
  );
  const { data: partnerVendors } = useVendorPartners().index();
  const selectedName: string = React.useMemo((): string => {
    if (partnerVendors == null || checkId === null) {
      return '';
    }
    if (checkId === UN_ASSIGNED) {
      return '未選択';
    }
    return (
      partnerVendors.find(
        (p: VendorPartner) => p.partnerVendorCompany?.id === checkId
      )?.partnerVendorCompany?.name ?? ''
    );
  }, [checkId, partnerVendors]);
  const onClickMenu = React.useCallback(
    (partnerVendorId: number): void => {
      if (checkId === partnerVendorId) {
        // 解除
        setCheckId(null);
        const { partnerVendorId: _oldId, ...removedCondition } = condition;
        handleChangeCondition(removedCondition).then();
        return;
      }
      setCheckId(partnerVendorId);
      handleChangeCondition({
        ...condition,
        partnerVendorId
      }).then();
    },
    [checkId, condition, handleChangeCondition]
  );
  const isSelected = checkId !== null;

  const renderRow = React.useCallback(
    (p: VendorPartner): ReactNode => {
      const partnerVendor = p.partnerVendorCompany;
      if (partnerVendor === null) {
        return null;
      }
      const isActive = partnerVendor.id === checkId;
      return (
        <PartnerMenu
          key={partnerVendor.id}
          isActive={isActive}
          onClickMenu={onClickMenu}
          partnerVendorCompany={partnerVendor}
        />
      );
    },
    [checkId, onClickMenu]
  );

  if (partnerVendors === null || partnerVendors.length === 0) {
    return null;
  }
  return (
    <div className={Styles.DropdownWrapper}>
      <SimpleDropdown
        className={cx(Styles.Dropdown, isSelected && Styles.Selected)}
        placement="bottom-start"
        title={
          <span className={Styles.DropdownTitle}>
            {isSelected ? (
              <span>
                <span className={Styles.SelectedTitle}>実施会社:</span>
                {selectedName}
              </span>
            ) : (
              '実施会社'
            )}
            <RiArrowDownSLine className={Styles.Caret} />
          </span>
        }
      >
        <DropdownList>
          <div className={Styles.ListBlock}>
            <DropdownMenu
              className={cx(
                Styles.SpecificMenu,
                checkId === UN_ASSIGNED ? Styles.Active : ''
              )}
              onClick={(): void => onClickMenu(UN_ASSIGNED)}
            >
              実施会社が未選択
            </DropdownMenu>
            <div className={Styles.SubLabel}>協力会社</div>
            <div className={Styles.ListArea}>
              {partnerVendors.map(renderRow)}
            </div>
          </div>
        </DropdownList>
      </SimpleDropdown>
    </div>
  );
};
