import React from 'react';
import Styles from './Page.module.scss';

export const WithoutPage: React.FC = () => (
  <div className={Styles.Card}>
    <h1 className={Styles.CardTitle}>案件はまだ届いていません</h1>
    <div className={Styles.Icon}>
      <img alt="ITANDI BB" className={Styles.Logo} src="/favicon.ico" />
    </div>
    <h1 className={Styles.Description}>連携会社からの発注完了をお待ち下さい</h1>
  </div>
);
