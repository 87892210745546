import React from 'react';
import { Accordion } from '@itandi/itandi-bb-ui';
import Styles from './CautionaryNoteHtml.module.scss';

type Props = DeepReadonly<{
  html: string;
}>;

export const CautionaryNoteHtml: React.FC<Props> = ({ html }: Props) => (
  <Accordion maxHeightForClosed={150}>
    <div
      className={Styles.CautionaryNoteHtmlContent}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  </Accordion>
);
