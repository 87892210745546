import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';
import { RiCalendarLine, RiCalendarTodoLine } from 'react-icons/ri';
import Styles from '../DealCard.module.scss';
import { formatDate } from '~/utils/DateUtil';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { EditMenuButton } from '~/components/common/parts/button/EditMenuButton';
import { ScheduleInputModal } from '~/components/common/construction_schedule/ScheduleInputModal';
import {
  DealConstructionSchedule,
  DealConstructionScheduleEdit
} from '~/models/DealConstructionSchedule';
import { NskResponse } from '~/models/Api';
import { create as createDealConstructionSchedule } from '~/utils/api/vendors/DealConstructionSchedule';
import { CONSTRUCTION_SCHEDULE_LABELS } from '~/constants/DealConstructionConst';

export const DealConstructionScheduleVendorCard: React.FC = () => {
  const router = useRouter();
  const { id = null } = router.query;
  const { data: deal, mutate: mutateDeal } = useDeals().show(
    id === null ? null : Number(id)
  );
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  if (deal === null || deal.dealConstructionSchedules.length === 0) {
    return null;
  }
  const handleSubmit = async (
    schedules: DealConstructionScheduleEdit[]
  ): Promise<NskResponse<void>> =>
    createDealConstructionSchedule(deal.id, schedules);
  const handleMutate = async (): Promise<void> => {
    mutateDeal && (await mutateDeal());
  };
  const renderSchedule = (schedule: DealConstructionSchedule): ReactNode => (
    <dl key={schedule.id} className={Styles.ListContent}>
      <dt className={Styles.ListContentCaption}>
        {CONSTRUCTION_SCHEDULE_LABELS[schedule.constructionScheduleType]}
      </dt>
      <dd className={Styles.CalendarContents}>
        <RiCalendarLine className={Styles.CalendarIcon} />
        {formatDate(schedule.startDate)}
        <span className={Styles.CalendarDelimiter}>-</span>
        {formatDate(schedule.endDate)}
      </dd>
    </dl>
  );
  return (
    <div className={Styles.DealCard}>
      <h3 className={Styles.Title}>
        <RiCalendarTodoLine className={Styles.TitleIcon} />
        工事日程
        <div className={Styles.EditButtonWrapper}>
          <EditMenuButton onClick={handleOpen}>変更</EditMenuButton>
          {isOpen && (
            <ScheduleInputModal
              deal={deal}
              handleClose={handleClose}
              handleMutate={handleMutate}
              handleSubmit={handleSubmit}
            />
          )}
        </div>
      </h3>
      <div className={Styles.Content}>
        {deal.dealConstructionSchedules.map(renderSchedule)}
      </div>
    </div>
  );
};
