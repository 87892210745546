import React, { ReactElement } from 'react';
import {
  Td,
  Tr,
  Chip,
  ContentCounter,
  StatusIcon,
  Text,
  TextLink,
  colors,
  colorUtility,
  Flex,
  Box,
  Grid
} from '@itandi/itandi-bb-ui';
import { RiLinkM } from 'react-icons/ri';
import { DealIndexClient } from '~/models/Deal';
import { PATH as CLIENT_DEAL_PATH } from '~/pages/deals/[id]';
import { DealTypeChip } from '~/components/common/parts/chip/DealTypeChip';
import { PATH as PROPERTY_DEAL_PATH } from '~/pages/property_deals/[id]/index';
import { formatHoursAndMinutes, formatMonthAndDay } from '~/utils/DateUtil';
import { truncate } from '~/utils/StringUtil';

type Props = {
  deal: DealIndexClient;
  statusNames: { [p: string]: string };
};

export const DealTableRow: React.FC<Props> = ({ deal, statusNames }: Props) => {
  const displayDate = (
    date: string | null,
    displayTime: boolean
  ): ReactElement => {
    if (date === null) {
      return (
        <Flex>
          <Box>
            <Text color="Sub" size="S">
              未定
            </Text>
          </Box>
          <Box marginTop={2}>
            <StatusIcon variant="Alert" />
          </Box>
        </Flex>
      );
    }
    return (
      <Grid alignItems="center" gap={4}>
        <Box>
          <Text size="S">{formatMonthAndDay(date)}</Text>
        </Box>
        {displayTime && (
          <Box>
            <Text size="S">{formatHoursAndMinutes(date)}</Text>
          </Box>
        )}
      </Grid>
    );
  };
  return (
    <Tr>
      <Td textAlign="Left" verticalAlign="Top">
        <Box marginY={2}>
          <Chip label={statusNames[deal.status]} />
        </Box>
        {deal.unreadMessageCount > 0 && (
          <Box marginY={2}>
            <Chip
              background={colors['semantic-negative']}
              borderColor={null}
              label={`未読${deal.unreadMessageCount}`}
              textColor={colors['itandi-white']}
            />
          </Box>
        )}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        <Box>
          <Flex gap={2}>
            <DealTypeChip
              dealSubtype={deal.dealSubtype}
              dealType={deal.dealType}
            />
            {deal.relatedDealCount > 0 && (
              <Chip
                background={colorUtility(colors['expressive-aqua-dark']).alpha(
                  0.1
                )}
                borderColor={colors['expressive-aqua-dark']}
                fontWeight="Bold"
                iconColor={colors['expressive-aqua-dark']}
                iconType={RiLinkM}
                label={`関連${deal.relatedDealCount}`}
                onClick={(): void => {
                  window.open(PROPERTY_DEAL_PATH(deal.propertyDealId));
                }}
                textColor={colors['expressive-aqua-dark']}
              />
            )}
          </Flex>
        </Box>
        <Box>
          <TextLink
            href={CLIENT_DEAL_PATH(deal.id)}
            target="_blank"
            variant="PrimaryBold"
          >
            {`${truncate(deal.name, 30)} ${deal.roomNumber || ''}`}
          </TextLink>
          <Flex gap={4}>
            <Box>
              <Text color="Sub" display="Block" size="S">
                {truncate(deal.address, 35)}
              </Text>
            </Box>
            {deal.tenantName && (
              <Box>
                <Text color="Sub" display="Block" size="S">
                  {`${truncate(deal.tenantName, 15)} 様`}
                </Text>
              </Box>
            )}
          </Flex>
        </Box>
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {deal.uncompletedTodoCount > 0 && (
          <ContentCounter
            count={deal.uncompletedTodoCount}
            onClick={(): void => {
              window.open(CLIENT_DEAL_PATH(deal.id));
            }}
            variant="Todo"
          />
        )}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        <Text display="Block" size="S">
          {deal.companyStoreSetting.name}
        </Text>
        <Text color="Sub" display="Block" size="XS">
          {deal.staff.name}
        </Text>
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {deal.dealVendorCompanies.length === 0 ? (
          <Flex>
            <Box>
              <Text color="Sub" display="Block" size="S">
                施工会社未指定
              </Text>
            </Box>
            <Box marginTop={2}>
              <StatusIcon variant="Alert" />
            </Box>
          </Flex>
        ) : (
          <>
            <Text display="Block" size="S">
              {deal.dealVendorCompanies[0].companyVendor?.name}
            </Text>
            {deal.dealVendorCompanies[0].user ? (
              <Text color="Sub" display="Block" size="XS">
                {deal.dealVendorCompanies[0].user.name}
              </Text>
            ) : (
              deal.dealVendorCompanies[0].vendorCompany && (
                <Flex>
                  <Box>
                    <Text color="Sub" display="Block" size="XS">
                      担当者設定なし
                    </Text>
                  </Box>
                  <Box marginTop={1}>
                    <StatusIcon variant="Alert" />
                  </Box>
                </Flex>
              )
            )}
          </>
        )}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.terminationDate, false)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.inspectionDate, true)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.constructionStartDate, false)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.constructionEndDate, false)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.preferredDate, false)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.createdAt, false)}
      </Td>
    </Tr>
  );
};
