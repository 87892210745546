import clsx from 'clsx';
import React, { ReactNode } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { RiSettings2Line } from 'react-icons/ri';
import Styles from './SettingLayout.module.scss';
import { PATH as COMPANY_SETTINGS_PATH } from '~/pages/settings/company_settings';
import { PATH as COMPANY_STORE_SETTINGS_PATH } from '~/pages/settings/company_store_settings';
import { PATH as STAFFS_PATH } from '~/pages/settings/staffs';
import { PATH as COMPANY_VENDORS_PATH } from '~/pages/settings/company_vendors';
import { PATH as COMPANY_NOTIFICATION_SETTINGS_PATH } from '~/pages/settings/company_notification_settings';
import { PATH as COMPANY_STORE_MAILS_PATH } from '~/pages/settings/company_store_mails';
import { PATH as COMPANY_NOTIFICATION_PATH } from '~/pages/settings/notifications';
import { PATH as SUBTYPE_PATH } from '~/pages/settings/subtypes';
import { PATH as IMPORT_PATH } from '~/pages/settings/imports';
import { PATH as FILE_TEMPLATE_PATH } from '~/pages/settings/file_templates';
import { AccordionSettingMenu } from '~/components/common/parts/accordion/setting/AccordionSettingMenu';
import useItandiAccount from '~/hooks/useItandiAccount';

type Props = {
  children: ReactNode;
};

export const ClientSettingLayout: React.FC<Props> = ({ children }: Props) => {
  const { data: session } = useItandiAccount().currentAccount();
  const currentPath: string = useRouter().pathname;
  const getActiveStyles = (targetPath: string): string => {
    if (currentPath.includes(targetPath)) {
      return Styles.ActiveMenu;
    }
    return '';
  };
  return (
    <div>
      <div className={Styles.SettingLayout}>
        <div className={Styles.SettingMenu}>
          <h2 className={Styles.SettingHeader}>設定</h2>
          {/* <p className={Styles.SettingSubHeader}> */}
          {/*  <RiUserLine className={Styles.HeaderIcon} /> */}
          {/*  個人設定 */}
          {/* </p> */}
          {/* <ul className={Styles.SettingList}> */}
          {/*  <li */}
          {/*    className={clsx( */}
          {/*      Styles.SettingItem, */}
          {/*      getActiveStyles(PROFILE_PATH) */}
          {/*    )} */}
          {/*  > */}
          {/*    <Link className={Styles.MenuLink} href={PROFILE_PATH}> */}
          {/*      プロフィール */}
          {/*    </Link> */}
          {/*  </li> */}
          {/* </ul> */}
          <p className={Styles.SettingSubHeader}>
            <RiSettings2Line className={Styles.HeaderIcon} />
            アカウント設定
          </p>
          <ul>
            {session?.isAdmin && (
              <li
                className={clsx(
                  Styles.SettingItem,
                  getActiveStyles(COMPANY_SETTINGS_PATH)
                )}
              >
                <Link className={Styles.MenuLink} href={COMPANY_SETTINGS_PATH}>
                  会社情報設定
                  <span className={Styles.AdminLabel}>Admin</span>
                </Link>
              </li>
            )}
            {session?.isAdmin && (
              <li
                className={clsx(
                  Styles.SettingItem,
                  getActiveStyles(COMPANY_STORE_SETTINGS_PATH)
                )}
              >
                <Link
                  className={Styles.MenuLink}
                  href={COMPANY_STORE_SETTINGS_PATH}
                >
                  店舗情報設定
                  <span className={Styles.AdminLabel}>Admin</span>
                </Link>
              </li>
            )}
            <li
              className={clsx(Styles.SettingItem, getActiveStyles(STAFFS_PATH))}
            >
              <Link className={Styles.MenuLink} href={STAFFS_PATH}>
                担当者一覧
              </Link>
            </li>
            <li
              className={clsx(
                Styles.SettingItem,
                getActiveStyles(COMPANY_VENDORS_PATH)
              )}
            >
              <Link className={Styles.MenuLink} href={COMPANY_VENDORS_PATH}>
                施工会社一覧
              </Link>
            </li>
            <li
              className={clsx(
                Styles.SettingParentItem,
                getActiveStyles(COMPANY_STORE_MAILS_PATH)
              )}
            >
              <AccordionSettingMenu
                currentPath={currentPath}
                menus={[
                  {
                    path: COMPANY_NOTIFICATION_SETTINGS_PATH,
                    title: 'メール通知設定'
                  },
                  {
                    path: COMPANY_STORE_MAILS_PATH,
                    title: '通知先メールアドレス設定'
                  }
                ]}
                title="メール設定"
              />
            </li>
            <li
              className={clsx(
                Styles.SettingItem,
                getActiveStyles(COMPANY_NOTIFICATION_PATH)
              )}
            >
              <Link
                className={Styles.MenuLink}
                href={COMPANY_NOTIFICATION_PATH}
              >
                通知設定
              </Link>
            </li>
            <li
              className={clsx(
                Styles.SettingItem,
                getActiveStyles(SUBTYPE_PATH)
              )}
            >
              <Link className={Styles.MenuLink} href={SUBTYPE_PATH}>
                案件タイプ設定
              </Link>
            </li>
            {session?.isAdmin && (
              <li
                className={clsx(
                  Styles.SettingItem,
                  getActiveStyles(IMPORT_PATH)
                )}
              >
                <Link className={Styles.MenuLink} href={IMPORT_PATH}>
                  過去データ取込
                  <span className={Styles.AdminLabel}>Admin</span>
                </Link>
              </li>
            )}
            <li
              className={clsx(
                Styles.SettingItem,
                getActiveStyles(FILE_TEMPLATE_PATH)
              )}
            >
              <Link className={Styles.MenuLink} href={FILE_TEMPLATE_PATH}>
                帳票設定
              </Link>
            </li>
            {/* <li */}
            {/*  className={clsx( */}
            {/*    Styles.SettingItem, */}
            {/*    getActiveStyles("") */}
            {/*  )} */}
            {/* > */}
            {/*  <Link  className={Styles.MenuLink} href={""}> */}
            {/*    ユーザー */}
            {/*  </Link> */}
            {/* </li> */}
            {/* <li */}
            {/*  className={clsx( */}
            {/*    Styles.SettingItem, */}
            {/*    getActiveStyles("") */}
            {/*  )} */}
            {/* > */}
            {/*  <Link className={Styles.MenuLink} href={""}> */}
            {/*    協力会社 */}
            {/*  </Link> */}
            {/* </li> */}
          </ul>
        </div>
        <div className={Styles.SettingContent}>{children}</div>
      </div>
    </div>
  );
};
