import React, { ReactNode } from 'react';
import { DealIndexVendor } from '~/models/Deal';
import { DealRow } from './DealRow';

type Props = {
  deals: DealIndexVendor[];
};

export const DealList: React.FC<Props> = ({ deals }: Props) => {
  const renderRow = (deal: DealIndexVendor): ReactNode => (
    <DealRow key={deal.id} deal={deal} />
  );
  return <div>{deals.map(renderRow)}</div>;
};
