import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RiFlagLine } from 'react-icons/ri';
import { InputRadio, ItandiChip } from '@itandi/itandi-bb-ui';
import Styles from './Block.module.scss';
import { DealParam } from '~/models/DealForm';
import { useSubtypes } from '~/hooks/useSubtypes';
import { Subtype } from '~/models/Subtype';

export const DealTypeBlock: React.FC = () => {
  const { data: subtypes } = useSubtypes().useIndex();
  const { register, formState, watch, setValue } = useFormContext<DealParam>();
  const { errors } = formState;
  if (subtypes === null) {
    return null;
  }
  return (
    <div className={Styles.Block}>
      <div className={Styles.BlockCaptionWrapper}>
        <div className={Styles.BlockCaption}>
          <span className={Styles.BlockIconWrapper}>
            <RiFlagLine className={Styles.BlockIcon} />
          </span>
          <span className={Styles.BlockCaptionLabel}>案件タイプ</span>
        </div>
      </div>
      <div className={Styles.BlockForms}>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>案件の種類</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputRadioGroup}>
              <InputRadio
                isInvalid={errors.dealType != null}
                label="原状回復"
                value="restoration"
                {...register('dealType')}
              />
              <InputRadio
                isInvalid={errors.dealType != null}
                label="修繕"
                value="repair"
                {...register('dealType')}
              />
              {subtypes.length > 0 && (
                <InputRadio
                  isInvalid={errors.dealType != null}
                  label="その他"
                  value="other"
                  {...register('dealType')}
                />
              )}
            </div>
            {errors.dealType != null && (
              <div className={Styles.ErrorMessage}>
                {errors.dealType.message}
              </div>
            )}
          </div>
        </div>
        {watch('dealType') === 'other' && (
          <div className={Styles.FormRow}>
            <div className={Styles.FormLabel}>
              <span className={Styles.FormLabelText}>案件のサブタイプ</span>
            </div>
            <div className={Styles.FormContent}>
              <div className={Styles.InputRadioGroup}>
                {subtypes?.map((subtype: Subtype) => (
                  <InputRadio
                    key={subtype.id}
                    checked={watch('dealSubtype')?.id === subtype.id}
                    isInvalid={errors.dealSubtype != null}
                    label={subtype.name}
                    name="dealSubtype"
                    onChange={(): void => {
                      setValue('dealSubtype', subtype);
                    }}
                    value={subtype.id}
                  />
                ))}
              </div>
              {errors.dealType != null && (
                <div className={Styles.ErrorMessage}>
                  {errors.dealType.message}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
