import React from 'react';
import cx from 'clsx';
import Styles from './SearchDropdown.module.scss';
import { useDropdownContext } from '../../parts/dropdown/Dropdown';
import { DropdownList } from '../../parts/dropdown/DropdownList';
import { SearchInput } from '../../parts/search/SearchInput';
import { CompanySetting } from '~/models/CompanySetting';
import { DropdownMenu } from '~/components/common/parts/dropdown/DropdownMenu';
import { useCompanySettings } from '~/hooks/vendors/useCompanySettings';

type Props = {
  onClickMenu: (id: number) => void;
  handleChangeSearchText: (searchWord: string) => void;
  searchText: string;
  checkId: number | null;
};

type RowProps = {
  companySetting: CompanySetting;
  isActive: boolean;
  onClickMenu: (id: number) => void;
};
const CompanyRow: React.FC<RowProps> = ({
  companySetting,
  onClickMenu,
  isActive
}: RowProps) => (
  <DropdownMenu
    className={cx(isActive && Styles.ActiveRow)}
    onClick={(): void => {
      onClickMenu(companySetting.companyMasterId);
    }}
  >
    {isActive && <span className={Styles.ActiveRowLabel}>[選択中]</span>}
    {companySetting.name}
  </DropdownMenu>
);

export const DealSearchCompanyIdList: React.FC<Props> = ({
  checkId,
  handleChangeSearchText,
  searchText,
  onClickMenu
}: Props) => {
  const { show } = useDropdownContext();
  const { data } = useCompanySettings().index(searchText, null);
  const searchInputRef = React.useRef<HTMLInputElement | null>(null);
  React.useEffect(() => {
    if (show && searchInputRef.current !== null) {
      searchInputRef.current.focus();
    }
  }, [show]);
  const renderRow = React.useCallback(
    (companySetting: CompanySetting) => (
      <CompanyRow
        key={companySetting.companyMasterId}
        companySetting={companySetting}
        isActive={companySetting.companyMasterId === checkId}
        onClickMenu={onClickMenu}
      />
    ),
    [checkId, onClickMenu]
  );
  return (
    <DropdownList>
      <div className={Styles.ListBlock}>
        <div className={Styles.SearchArea}>
          <SearchInput
            ref={searchInputRef}
            handleChange={handleChangeSearchText}
            placeholder="管理会社名で検索"
          />
        </div>
        <div className={Styles.SubLabel}>管理会社</div>
        <div className={Styles.ListArea}>
          {data !== null && data.objects.map(renderRow)}
        </div>
      </div>
    </DropdownList>
  );
};
