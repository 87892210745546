import React from 'react';
import { useRouter } from 'next/router';
import { Pagination } from '@itandi/itandi-bb-ui';
import Styles from './Page.module.scss';
import { VendorLayoutV2 } from '~/components/common/layouts/VendorLayoutV2';
import { useDeals } from '~/hooks/vendors/useDeals';
import {
  buildSearchConditionByParams,
  convertQueryStringUrl
} from '~/utils/DealSearchConditionUtils';
import { DealSearchCondition } from '~/models/Deal';
import { DealSearch } from '~/components/specifics/vendors/deals/search/DealSearch';
import { DealList } from '~/components/specifics/vendors/deals/list/DealList';
import { WithoutPage } from './WithoutPage';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { DealSearchDetail } from '~/components/specifics/vendors/deals/search/DealSearchDetail';
import { PATH as PAGE_PATH } from '~/pages/vendors/deals';

export const Page: React.FC = () => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const [condition, setCondition] = React.useState<DealSearchCondition>({});
  const router = useRouter();
  const { query } = useRouter();
  const dealsApi = useDeals();
  const { data: paginateDeal, isLoading } = dealsApi.index(condition);
  React.useEffect(() => {
    const newCondition = buildSearchConditionByParams(query);
    setCondition(newCondition);
    const detailConditionKeys = Object.keys(newCondition).filter(
      (key) => !['name', 'page'].includes(key)
    );
    if (detailConditionKeys.length > 0) {
      handleOpen();
    }
  }, [query, handleOpen]);
  const handleChangePage = React.useCallback(
    async (newPageNumber: number) => {
      const newCondition = { ...condition, page: newPageNumber };
      const newUrl = convertQueryStringUrl(PAGE_PATH, newCondition);
      await router.replace(newUrl);
    },
    [condition, router]
  );
  return (
    <VendorLayoutV2>
      <div className={Styles.Container}>
        <h1 className={Styles.Title}>
          案件一覧
          {paginateDeal !== null && (
            <small className={Styles.TitleCount}>{paginateDeal.count}件</small>
          )}
        </h1>
        <DealSearch
          condition={condition}
          handleDetailClose={handleClose}
          handleDetailOpen={handleOpen}
          isDetailOpen={isOpen}
        />
        <DealSearchDetail condition={condition} isOpen={isOpen} />
        <div className={Styles.ListContainer}>
          {paginateDeal !== null && <DealList deals={paginateDeal.objects} />}
        </div>
        <div className={Styles.PaginationWrapper}>
          {paginateDeal !== null && (
            <Pagination
              currentPageNum={query.page ? Number(query.page) : 1}
              handleChangePage={handleChangePage}
              maxPageNum={paginateDeal.sumPageCount}
            />
          )}
        </div>
        {/* すべての案件が完了のとき検索できなくなるため検索バーを表示 */}
        {paginateDeal !== null &&
          paginateDeal.count === 0 &&
          Object.keys(query).length === 0 &&
          !isLoading && <WithoutPage />}
      </div>
    </VendorLayoutV2>
  );
};
