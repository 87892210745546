import React from 'react';
import { RiCommunityLine } from 'react-icons/ri';
import Styles from '../DealCard.module.scss';
import { getOriginDealVendor } from '~/models/DealVendorCompany';
import { useDeals } from '~/hooks/useDeals';
import { isWithVendorDeal } from '~/models/Deal';
import { useMessages } from '~/hooks/deals/useMessages';
import { getUnreadCount } from '~/models/Message';
import { VENDOR_COMPANY_ITANDI_ID } from '~/models/VendorCompany';
import { EditMenuButton } from '~/components/common/parts/button/EditMenuButton';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { DealOriginVendorClientChangeModal } from '~/components/common/deals/show/vendor_card/DealOriginVendorClientChangeModal';
import { CautionaryNoteHtml } from '~/components/common/cautionary_note_html/CautionaryNoteHtml';

type Props = DeepReadonly<{
  handleToggleChat?: (() => void) | null;
}>;

/**
 * 管理会社向けに施工会社の情報を表示する。
 * 施工会社が二次請け構造のとき、管理会社は一次請けの会社のみ見ることができる
 *
 * @constructor
 * @for client
 */
export const DealOriginVendorClientCard: React.FC<Props> = ({
  handleToggleChat = null
}) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: messages } = useMessages().index(id);
  const unreadMessageCount = React.useMemo((): number => {
    if (messages === null) {
      return 0;
    }
    return getUnreadCount(messages, 'maintenance_company');
  }, [messages]);
  if (deal === null) return null;
  const dealVendor = getOriginDealVendor(deal);
  if (dealVendor === null) {
    return null;
  }
  return (
    <div className={Styles.DealCard}>
      <h3 className={Styles.Title}>
        <RiCommunityLine className={Styles.TitleIcon} />
        施工会社情報
        <div className={Styles.EditButtonWrapper}>
          {dealVendor.vendorCompany?.id !== VENDOR_COMPANY_ITANDI_ID && (
            <EditMenuButton onClick={handleOpen}>変更</EditMenuButton>
          )}
          {isOpen && (
            <DealOriginVendorClientChangeModal handleClose={handleClose} />
          )}
        </div>
      </h3>
      <div className={Styles.Content}>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>会社名</dt>
          <dd className={Styles.ListContentValue}>
            {dealVendor.companyVendor?.name}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>担当者</dt>
          <dd className={Styles.ListContentValue}>
            {dealVendor.user === null ? (
              <span className={Styles.NotYet}>未定</span>
            ) : (
              <>
                <div>{dealVendor.user.name}</div>
                <div>
                  <a
                    className={Styles.Link}
                    href={`tel:${dealVendor.user.tel}`}
                  >
                    {dealVendor.user.tel}
                  </a>
                </div>
                <div>
                  <a
                    className={Styles.Link}
                    href={`mailto:${dealVendor.user.email}`}
                  >
                    {dealVendor.user.email}
                  </a>
                </div>
              </>
            )}
          </dd>
        </dl>
        {dealVendor.cautionaryNoteHtml !== '' && (
          <dl className={Styles.ListContent}>
            <dt className={Styles.ListContentCaption}>注意事項</dt>
            <dd className={Styles.ListContentValue}>
              <CautionaryNoteHtml html={dealVendor.cautionaryNoteHtml} />
            </dd>
          </dl>
        )}
        {dealVendor.vendorCompany?.id !== VENDOR_COMPANY_ITANDI_ID && (
          <dl className={Styles.ListContent}>
            <dt className={Styles.ListContentCaption}>個人情報公開</dt>
            <dd className={Styles.ListContentValue}>
              {dealVendor.showTenant ? '公開' : '非公開'}
            </dd>
          </dl>
        )}
      </div>
      {isWithVendorDeal(deal) && handleToggleChat !== null && (
        <div className={Styles.ButtonArea}>
          <button
            className={Styles.Button}
            onClick={handleToggleChat}
            type="button"
          >
            施工会社とチャット
            {unreadMessageCount > 0 && (
              <span className={Styles.UnreadCountBadge}>
                {unreadMessageCount}
              </span>
            )}
          </button>
        </div>
      )}
    </div>
  );
};
