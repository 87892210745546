import React from 'react';
import Styles from './DealSearchName.module.scss';
import { SearchInput } from '~/components/common/parts/search/SearchInput';
import { DealSearchCondition } from '~/models/Deal';

type Props = {
  condition: DealSearchCondition;
  handleChangeCondition: (condition: DealSearchCondition) => Promise<void>;
};

export const DealSearchName: React.FC<Props> = ({
  condition,
  handleChangeCondition
}: Props) => {
  const { name } = condition;
  const searchInputRef = React.useRef(null);
  const handleChangeName = async (searchWord: string): Promise<void> => {
    handleChangeCondition({
      ...condition,
      name: searchWord
    }).then();
  };
  return (
    <div className={Styles.SearchInputWrapper}>
      <SearchInput
        ref={searchInputRef}
        defaultValue={name ?? ''}
        handleChange={handleChangeName}
        placeholder="案件名で検索"
      />
    </div>
  );
};
