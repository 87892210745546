import React from 'react';
import { toast } from 'react-toastify';
import { Alert, Flex } from '@itandi/itandi-bb-ui';
import Styles from './DealMainWorkflow.module.scss';
import { StatusBar } from '~/components/common/parts/status/StatusBar';
import { useStatuses } from '~/hooks/vendors/useStatuses';
import { getWithVendorStatuses, isCanceled } from '~/models/Status';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { create as postCancel } from '~/utils/api/vendors/deals/DealCancel';
import { create as postCancelBack } from '~/utils/api/vendors/deals/DealCancelBack';
import { isSuccess } from '~/utils/api/api_handler';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const DealMainWorkflowVendorCancel: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const {
    isOpen: confirmOpen,
    handleOpen: handleConfirmOpen,
    handleClose: handleConfirmClose
  } = useOpenHandler();
  const {
    isOpen: confirmBackOpen,
    handleOpen: handleConfirmBackOpen,
    handleClose: handleConfirmBackClose
  } = useOpenHandler();
  const id = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(id);
  const { data: statuses } = useStatuses().index();
  if (deal === null || statuses === null) {
    return null;
  }
  const displayStatuses = getWithVendorStatuses(statuses);
  const handleAcceptCancel = async (): Promise<void> => {
    setIsSubmitting(true);
    const result = await postCancel(deal.id);
    if (isSuccess(result)) {
      mutateDeal && (await mutateDeal());
      toast.success('取り下げを受理しました');
      handleConfirmClose();
      return;
    }
    toast.error('エラーが発生しました');
    setIsSubmitting(false);
  };
  const handleBackCancel = async (): Promise<void> => {
    const result = await postCancelBack(deal.id);
    if (isSuccess(result)) {
      mutateDeal && (await mutateDeal());
      toast.success('案件の取り下げを差し戻しました');
      handleConfirmClose();
      return;
    }
    toast.error('エラーが発生しました');
    setIsSubmitting(false);
  };
  return (
    <div>
      {!isCanceled(deal.status) && (
        <div className={Styles.StatusCard}>
          <h2 className={Styles.Header}>
            {deal.companySetting.name}からの依頼
          </h2>
          <StatusBar currentId={null} statuses={displayStatuses} />
        </div>
      )}
      <div className={Styles.WorkflowCard}>
        <h3 className={Styles.CancelTitle}>
          {isCanceled(deal.status)
            ? 'この案件は取り下げられました'
            : 'この案件の取り下げ申請が届きました'}
        </h3>
        <div>
          <div className={Styles.CancelReasonLabel}>取り下げ理由</div>
          {deal.dealCancel !== null && (
            <div className={Styles.CancelReason}>{deal.dealCancel.reason}</div>
          )}
          {!isCanceled(deal.status) && (
            <div className={Styles.StatusHandleButtonRow}>
              <div className={Styles.RightButtons}>
                <button
                  className={Styles.BackwardButton}
                  onClick={handleConfirmBackOpen}
                  type="button"
                >
                  取り下げを差し戻して案件を継続する
                </button>
                <button
                  className={Styles.ForwardButton}
                  onClick={handleConfirmOpen}
                  type="button"
                >
                  取り下げを受理する
                </button>
              </div>
              <ConfirmModal
                actionText="継続"
                confirmType="save"
                disabled={isSubmitting}
                handleAccept={handleBackCancel}
                handleCancel={handleConfirmBackClose}
                isOpen={confirmBackOpen}
              >
                取り下げを差し戻してこのまま案件を継続してよろしいですか？
              </ConfirmModal>
              <ConfirmModal
                actionText="受理して終了"
                confirmType="delete"
                disabled={isSubmitting}
                handleAccept={handleAcceptCancel}
                handleCancel={handleConfirmClose}
                isOpen={confirmOpen}
              >
                取り下げを受理してこの案件を終了してよろしいですか？
                <Flex marginTop="8px">
                  <Alert variant="info">
                    受理すると、実施会社・管理会社それぞれに通知メールが送信されます。
                  </Alert>
                </Flex>
              </ConfirmModal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
