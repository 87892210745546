import React from 'react';
import { useRouter } from 'next/router';
import { Button } from '@itandi/itandi-bb-ui';
import { RiArrowDownSLine } from 'react-icons/ri';
import clsx from 'clsx';
import Styles from './DealSearch.module.scss';
import { PATH } from '~/pages/vendors/deals';
import { DealSearchCondition } from '~/models/Deal';
import { DealSearchName } from '~/components/common/deals/search/DealSearchName';
import { convertQueryStringUrl } from '~/utils/DealSearchConditionUtils';

type Props = {
  condition: DealSearchCondition;
  isDetailOpen: boolean;
  handleDetailOpen: () => void;
  handleDetailClose: () => void;
};

export const DealSearch: React.FC<Props> = ({
  condition,
  isDetailOpen,
  handleDetailOpen,
  handleDetailClose
}: Props) => {
  const router = useRouter();
  const handleChangeCondition = React.useCallback(
    async (newCondition: DealSearchCondition): Promise<void> => {
      const newUrl = convertQueryStringUrl(PATH, newCondition);
      await router.replace(newUrl);
    },
    [router]
  );
  const handleToggleDetail = React.useCallback(() => {
    if (isDetailOpen) {
      handleDetailClose();
    } else {
      handleDetailOpen();
    }
  }, [isDetailOpen, handleDetailOpen, handleDetailClose]);
  return (
    <div className={Styles.SearchContainer}>
      <DealSearchName
        condition={condition}
        handleChangeCondition={handleChangeCondition}
      />
      <Button
        className={clsx(Styles.DetailButton, isDetailOpen && Styles.DetailOpen)}
        endIcon={<RiArrowDownSLine />}
        height="small"
        onClick={handleToggleDetail}
        variant="secondary"
      >
        詳細検索
      </Button>
    </div>
  );
};
