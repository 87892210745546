import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Input,
  Button,
  FormControl,
  InputSelect,
  ConfirmModal
} from '@itandi/itandi-bb-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import {
  CompanyStoreSetting,
  CompanyStoreSettingEdit,
  validationSchemaCompanyStoreSetting
} from '~/models/CompanyStoreSetting';
import Styles from './CompanyStoreSettingForm.module.scss';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { update } from '~/utils/api/admin/CompanyStoreSetting';
import { isSuccess } from '~/utils/api/api_handler';

type Props = {
  companyStoreSetting: CompanyStoreSetting;
};

type Prefecture = {
  id: number;
  name: string;
};

const prefectures: Prefecture[] = [
  { id: 1, name: '東京都' },
  { id: 2, name: '神奈川県' },
  { id: 3, name: '千葉県' },
  { id: 4, name: '埼玉県' },
  { id: 5, name: '大阪府' }
];
export const CompanyStoreSettingForm: React.FC<Props> = ({
  companyStoreSetting
}) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const { register, getValues, formState, handleSubmit } =
    useForm<CompanyStoreSettingEdit>({
      defaultValues: companyStoreSetting,
      mode: 'onBlur',
      resolver: yupResolver(validationSchemaCompanyStoreSetting)
    });
  const onClickSubmit = React.useCallback(() => {
    handleSubmit(() => {
      handleOpen();
    })();
  }, [handleSubmit, handleOpen]);
  const handleAccept = React.useCallback(async () => {
    const values = getValues();
    const response = await update(values);
    if (isSuccess(response)) {
      toast.success('保存しました');
      handleClose();
      return;
    }
    toast.error('保存に失敗しました');
  }, [getValues, handleClose]);
  const { errors } = formState;
  return (
    <div className={Styles.FormContainer}>
      <FormControl chip="必須" label="郵便番号" variant="Vertical">
        <Input isInvalid={errors.zipcode != null} {...register('zipcode')} />
        {errors.zipcode != null && (
          <div className={Styles.InvalidFeedback}>{errors.zipcode.message}</div>
        )}
      </FormControl>
      <FormControl chip="必須" label="都道府県" variant="Vertical">
        <InputSelect {...register('state')} fullWidth>
          <option value="">選択してください</option>
          {prefectures.map((p: Prefecture) => (
            <option key={p.id} value={p.name}>
              {p.name}
            </option>
          ))}
        </InputSelect>
      </FormControl>
      <FormControl chip="必須" label="市区町村・番地" variant="Vertical">
        <Input isInvalid={errors.city != null} {...register('city')} />
        {errors.city != null && (
          <div className={Styles.InvalidFeedback}>{errors.city.message}</div>
        )}
      </FormControl>
      <FormControl chip="推奨" label="建物・部屋番号" variant="Vertical">
        <Input {...register('otherAddress')} />
      </FormControl>
      <FormControl chip="必須" label="店舗の代表電話番号" variant="Vertical">
        <Input
          isInvalid={errors.phoneNumber != null}
          {...register('phoneNumber')}
        />
        {errors.phoneNumber != null && (
          <div className={Styles.InvalidFeedback}>
            {errors.phoneNumber.message}
          </div>
        )}
      </FormControl>
      <div>
        <Button onClick={onClickSubmit} variant="primary">
          保存
        </Button>
      </div>
      <ConfirmModal
        handleClose={handleClose}
        handleSubmit={handleAccept}
        headerTitle="確認"
        isOpen={isOpen}
      >
        保存してよろしいですか？
      </ConfirmModal>
    </div>
  );
};
