import React from 'react';
import { ItandiHeader, ItandiHeaderItem } from '@itandi/itandi-bb-ui';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import useItandiAccount from '~/hooks/useItandiAccount';
import { logout as logoutStaff } from '~/utils/api/Staff';
import { logout as logoutStore } from '~/utils/api/ItandiAccountSession';
import { PATH as ADMIN_COMPANY_SETTINGS_PATH } from '~/pages/admin';
import { PATH as ADMIN_VENDOR_COMPANIES_PATH } from '~/pages/admin/vendor_companies';
import { PATH as DEALS_PATH } from '~/pages/deals';
import { PATH as MESSAGES_PATH } from '~/pages/messages';
import { PATH as SETTINGS_PATH } from '~/pages/settings';
import { PATH as FORBIDDEN_PATH } from '~/pages/403';
import { isSuccess } from '~/utils/api/api_handler';
import { destroy as logoutIaUser } from '~/utils/api/ItandiUserSessions';
import { canOpenSetting } from '~/models/Staff';

const isActivePage = (currentPath: string, targetPath: string): boolean => {
  if (targetPath === '/admin') {
    return currentPath === '/admin';
  }
  if (targetPath === '/') {
    return currentPath === '/';
  }
  return currentPath.includes(targetPath);
};

export const ItandiHeaderContainer: React.FC = () => {
  const router = useRouter();
  const { data: currentAccount, mutate: mutateAccount } =
    useItandiAccount().currentAccount();
  const handleChangeStaff = React.useCallback(async () => {
    if (currentAccount?.company?.useIaStaffLogin) {
      const response = await logoutIaUser();
      if (isSuccess(response)) {
        response.data && window.location.replace(response.data.url);
      }
    } else {
      await logoutStaff();
      mutateAccount && (await mutateAccount());
    }
  }, [currentAccount?.company?.useIaStaffLogin, mutateAccount]);
  const handleLogout = React.useCallback(async () => {
    const response = await logoutStore();
    if (isSuccess(response)) {
      response.data && window.location.replace(response.data.url);
      return;
    }
    toast.error('エラーが発生しました');
  }, []);
  const itandiAccount = React.useMemo(() => {
    if (
      currentAccount === null ||
      currentAccount.company === null ||
      currentAccount.store === null
    ) {
      return null;
    }
    return {
      companyName: currentAccount.company.name,
      storeName: currentAccount.store.name
    };
  }, [currentAccount]);
  const staffAccount = React.useMemo(() => {
    if (currentAccount === null || currentAccount.staff === null) {
      return null;
    }
    return {
      name: currentAccount.staff.name
    };
  }, [currentAccount]);
  const isShowAdminHeader = React.useMemo((): boolean => {
    if (currentAccount === null || !currentAccount.isAdmin) {
      return false;
    }
    if (router.route.includes('/admin')) {
      return true;
    }
    return currentAccount.company === null;
  }, [currentAccount, router]);
  if (currentAccount === null) {
    return null;
  }
  return (
    <ItandiHeader
      handleChangeStaff={handleChangeStaff}
      handleLogout={handleLogout}
      isAdmin={currentAccount.isAdmin}
      itandiAccount={itandiAccount}
      productCode="nsk"
      productParams={currentAccount.productParams}
      staffAccount={staffAccount}
    >
      {isShowAdminHeader ? (
        <>
          <ItandiHeaderItem
            active={isActivePage(router.pathname, ADMIN_COMPANY_SETTINGS_PATH)}
            label="管理会社一覧"
            url={ADMIN_COMPANY_SETTINGS_PATH}
          />
          <ItandiHeaderItem
            active={isActivePage(router.pathname, ADMIN_VENDOR_COMPANIES_PATH)}
            label="施工会社一覧"
            url={ADMIN_VENDOR_COMPANIES_PATH}
          />
        </>
      ) : (
        <>
          <ItandiHeaderItem
            active={isActivePage(router.pathname, DEALS_PATH)}
            label="案件一覧"
            url={DEALS_PATH}
          />
          <ItandiHeaderItem
            active={isActivePage(router.pathname, MESSAGES_PATH)}
            label="チャット"
            url={MESSAGES_PATH}
          />
          <ItandiHeaderItem
            active={isActivePage(router.pathname, SETTINGS_PATH)}
            label="設定"
            url={
              canOpenSetting(currentAccount) ? SETTINGS_PATH : FORBIDDEN_PATH
            }
          />
          {currentAccount.isAdmin && (
            <ItandiHeaderItem
              active={false}
              label="Admin"
              url={ADMIN_COMPANY_SETTINGS_PATH}
            />
          )}
        </>
      )}
    </ItandiHeader>
  );
};
