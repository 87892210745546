import React from 'react';
import Loader from '~/components/common/parts/loading/Loading';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/vendors/deals';
import { PATH as DEAL_PATH } from '~/pages/vendors/deals/[id]';
import { VendorLayoutV2 } from '~/components/common/layouts/VendorLayoutV2';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { DealCard } from '~/components/common/deals/show/deal_card/DealCard';
import { DealInspectionVendorCard } from '~/components/common/deals/show/inspection_card/DealInspectionVendorCard';
import { DealConstructionScheduleVendorCard } from '~/components/common/deals/show/construction_card/DealConstructionScheduleVendorCard';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useSessions } from '~/hooks/vendors/useSessions';
import { DealOriginVendorCard } from '~/components/common/deals/show/vendor_card/DealOriginVendorCard';
import { DealSubVendorCard } from '~/components/common/deals/show/vendor_card/DealSubVendorCard';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { DealRepairCard } from '~/components/common/deals/show/repair_card/DealRepairCard';

export const Page: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal, isLoading } = useDeals().show(id);
  const { data: session } = useSessions().index();
  if (isLoading || deal === null) {
    return <Loader isLoading />;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: DEAL_PATH(deal.id), caption: '案件詳細', active: false },
    { path: null, caption: '案件内容', active: true }
  ];
  return (
    <VendorLayoutV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor
        noBackground
      >
        <DealCard deal={deal} vendorSession={session} />
        <DealOriginVendorCard />
        {deal.dealType === 'repair' && (
          <DealRepairCard companyType="vendor" deal={deal} />
        )}
        <DealSubVendorCard />
        <DealInspectionVendorCard />
        <DealConstructionScheduleVendorCard />
      </DealLayout>
    </VendorLayoutV2>
  );
};
