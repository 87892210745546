import React from 'react';
import { useForm } from 'react-hook-form';
import { Input, InputRadio, Button } from '@itandi/itandi-bb-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import {
  AdminSettingCompanySetting,
  CompanySetting,
  validationSchemaAdminCompanySetting
} from '~/models/CompanySetting';
import { FormGroup } from '~/components/common/parts/form/FormGroup';
import { FormLabel } from '~/components/common/parts/form/FormLabel';
import Styles from './CompanySettingForm.module.scss';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { update } from '~/utils/api/CompanySetting';
import { isSuccess } from '~/utils/api/api_handler';

type Props = {
  companySetting: CompanySetting;
};
export const CompanySettingForm: React.FC<Props> = ({ companySetting }) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const { register, watch, getValues, formState, setValue, handleSubmit } =
    useForm<AdminSettingCompanySetting>({
      defaultValues: companySetting,
      mode: 'onBlur',
      resolver: yupResolver(validationSchemaAdminCompanySetting)
    });
  const onClickSubmit = React.useCallback(() => {
    handleSubmit(() => {
      handleOpen();
    })();
  }, [handleSubmit, handleOpen]);
  const handleAccept = React.useCallback(async () => {
    const values = getValues();
    const response = await update(values);
    if (isSuccess(response)) {
      toast.success('保存しました');
      handleClose();
      return;
    }
    toast.error('保存に失敗しました');
  }, [getValues, handleClose]);
  const { errors } = formState;
  const fromMarket = watch('fromMarket');
  const useIaStaffLogin = watch('useIaStaffLogin');
  const useBkkLinkage = watch('useBkkLinkage');
  return (
    <div className={Styles.FormContainer}>
      <FormGroup>
        <FormLabel>会社名</FormLabel>
        <Input {...register('name')} />
        {errors.name != null && (
          <div className={Styles.InvalidFeedback}>{errors.name.message}</div>
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel>登録経路</FormLabel>
        <div className={Styles.RadioGroup}>
          <InputRadio
            checked={fromMarket === false}
            label="SaaS"
            name="fromMarket"
            onChange={(e): void => {
              setValue('fromMarket', e.target.value === 'true');
            }}
            value="false"
          />
          <InputRadio
            checked={fromMarket === true}
            label="マーケットプレイス"
            name="fromMarket"
            onChange={(e): void => {
              setValue('fromMarket', e.target.value === 'true');
            }}
            value="true"
          />
        </div>
        {errors.fromMarket != null && (
          <div className={Styles.InvalidFeedback}>
            {errors.fromMarket.message}
          </div>
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel>物件管理くん連携を利用するか</FormLabel>
        <div className={Styles.RadioGroup}>
          <InputRadio
            checked={useBkkLinkage === false}
            label="利用しない"
            name="useBkkLinkage"
            onChange={(e): void => {
              setValue('useBkkLinkage', e.target.value === 'true');
            }}
            value="false"
          />
          <InputRadio
            checked={useBkkLinkage === true}
            label="利用する"
            name="useBkkLinkage"
            onChange={(e): void => {
              setValue('useBkkLinkage', e.target.value === 'true');
            }}
            value="true"
          />
        </div>
        {errors.useBkkLinkage != null && (
          <div className={Styles.InvalidFeedback}>
            {errors.useBkkLinkage.message}
          </div>
        )}
      </FormGroup>
      <FormGroup>
        <FormLabel>イタンジアカウントの担当者を利用するか</FormLabel>
        <div className={Styles.RadioGroup}>
          <InputRadio
            checked={useIaStaffLogin === false}
            label="利用しない"
            name="useIaStaffLogin"
            onChange={(e): void => {
              setValue('useIaStaffLogin', e.target.value === 'true');
            }}
            value="false"
          />
          <InputRadio
            checked={useIaStaffLogin === true}
            label="利用する"
            name="useIaStaffLogin"
            onChange={(e): void => {
              setValue('useIaStaffLogin', e.target.value === 'true');
            }}
            value="true"
          />
        </div>
        {errors.useIaStaffLogin != null && (
          <div className={Styles.InvalidFeedback}>
            {errors.useIaStaffLogin.message}
          </div>
        )}
      </FormGroup>
      <div>
        <Button onClick={onClickSubmit} variant="primary">
          保存
        </Button>
      </div>
      <ConfirmModal
        confirmType="save"
        handleAccept={handleAccept}
        handleCancel={handleClose}
        isOpen={isOpen}
      >
        保存してよろしいですか？
      </ConfirmModal>
    </div>
  );
};
